import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";

function SoilCarbonStock() {
  return (
    <Paper
      elevation={0}
      className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden mb-8 py-4"
    >
      <div className="text-center  px-8 ">
        <Typography
          variant="p"
          className="px-8 text-md  font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          Soil Carbon
        </Typography>
      </div>
      <div className="text-center mt-8 flex flex-col">
        <Typography
          variant="p"
          className="text-xl sm:text-3xl font-bold tracking-tight leading-none text-green-500"
        >
          0
        </Typography>
        <Typography variant="p" className="text-md font-medium py-3 text-green-600">
          tCO2e
        </Typography>
      </div>
      {/* <Typography
        variant="p"
        className="flex items-baseline justify-center w-full pt-8 pb-24"
        color="text.secondary"
      >
        <br />
      </Typography> */}
    </Paper>
  );
}

export default memo(SoilCarbonStock);
