import React, { useState } from "react";
import {
  Avatar,
  Typography,
  Box,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
// import moment from "moment";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { backend_url } from "../services/Constants";
import MRVSnackbar from "../components/MRVSnackbar";
import Loading from "../components/Loading";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { lighten } from "@mui/material/styles";

export const UpdateProfile = (props) => {
  const { token, user } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const schema = yup.object().shape({
    first_name: yup.string().required("This field is required"),
    last_name: yup.string().required("This field is required"),
    location: yup.string().required("This field is required"),
    about_me: yup.string().required("This field is required"),
    profile_photo: yup.mixed().notRequired(),
  });

  const defaultValues = {
    first_name: user !== undefined ? user.first_name : "",
    last_name: user !== undefined ? user.last_name : "",
    about_me: user !== undefined ? user.about_me : "",
    location: user !== undefined ? user.location : "",
    profile_photo: null,
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submit({
    profile_photo,
    first_name,
    last_name,
    about_me,
    location,
  }) {
    const formData = new FormData();
    const fileExtension = profile_photo.name.split(".").pop().toLowerCase();
    if (
      fileExtension === "png" ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg"
    ) {
      formData.append("profile_photo", profile_photo);
    }

    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("location", location);
    formData.append("about_me", about_me);

    axios
      .patch(
        `${backend_url}/auth/user/update_info/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Updated profile information Successfully");
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.response.data.details);
        setLoading(false);
      });
  }

  return (
    user.username !== undefined && (
      <React.Fragment>
        <Button
          className="whitespace-nowrap"
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
          size="medium"
        >
          Edit Profile
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <Typography
              component="h5"
              variant="p"
              className="px-4 py-16 text-sm md:text-md font-semibold sm:font-bold"
            >
              Update Profile
            </Typography>
          </DialogTitle>
          <DialogContent>
            <form
              name="profileForm"
              noValidate
              className="flex flex-col justify-center w-full mt-8 sm:mt-8  md:min-w-320"
              onSubmit={handleSubmit(submit)}
            >
              {openMessage === true && (
                <MRVSnackbar
                  openMessage={openMessage}
                  severity={severity}
                  message={message}
                />
              )}

              <Controller
                control={control}
                name="profile_photo"
                render={({ field: { onChange, value } }) => (
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? lighten(theme.palette.background.default, 1)
                          : lighten(theme.palette.background.default, 0.02),
                      borderRadius: "50%",
                      width: 150, // Set the width and height to make the box circular
                      height: 150,
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      cursor: "pointer",
                      boxShadow: 3,
                      "&:hover": {
                        boxShadow: 6,
                      },
                    }}
                    component="label"
                    htmlFor="button_profile_photo"
                    className="mx-12 mb-24"
                  >
                    <input
                      accept=".jpeg, .jpg, .png, .PNG,"
                      className="hidden"
                      id="button_profile_photo"
                      type="file"
                      onChange={(e) => onChange(e.target.files[0])}
                    />
                    {value ? (
                      <Avatar
                        src={URL.createObjectURL(value)}
                        sx={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <>
                        <IconButton sx={{ color: "action" }}>
                          <FileUploadIcon />
                        </IconButton>
                        <Typography
                          variant="caption"
                          component="span"
                          sx={{ mt: 1 }} // Margin top to create space between the icon and text
                        >
                          Update Profile Photo
                        </Typography>
                      </>
                    )}
                    <FormHelperText
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <span className="flex flex-col text-red-500">
                        {errors?.profile_photo?.message}
                      </span>
                    </FormHelperText>
                  </Box>
                )}
              />

              <div className="sm:flex my-4">
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ m: 1 }}
                      id="first_name"
                      label="First Name"
                      error={!!errors.first_name}
                      helperText={errors?.first_name?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>

              <div className="sm:flex my-4">
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ m: 1 }}
                      id="last_name"
                      label="Last Name"
                      error={!!errors.last_name}
                      helperText={errors?.last_name?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>

              <div className="sm:flex my-4">
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ m: 1 }}
                      id="location"
                      label="Location (City-Country)"
                      error={!!errors.location}
                      helperText={errors?.location?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>

              <div className="sm:flex my-4">
                <Controller
                  name="about_me"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ m: 1 }}
                      id="about_me"
                      label="About Me"
                      error={!!errors.about_me}
                      helperText={errors?.about_me?.message}
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      minRows={2}
                    />
                  )}
                />
              </div>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  sx={{ mx: 1 }}
                  variant="contained"
                  color="error"
                  // disabled={_.isEmpty(dirtyFields) || !isValid}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ mx: 1 }}
                  variant="contained"
                  color="success"
                  // disabled={_.isEmpty(dirtyFields) || !isValid}
                  onClick={() => {
                    setOpenMessage(false);
                    setLoading(true);
                  }}
                  type="submit"
                >
                  {loading === true ? <Loading /> : "Submit"}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  );
};
