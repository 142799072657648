import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { Box, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import logo1 from "../../assets/group_4035.png";
import { Link } from "react-router-dom";

const DrawerHead = styled("div")(({ theme }) => ({
  // display: 'flex',
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const DrawerHeader = (props) => {
  const { handleDrawerToggle, open } = props;

  return (
    <div>
      <div className="grid grid-cols-12 gap-x-4 mx-4 mt-4 ">
        <div className="col-span-10 self-center tracking-tight">
          <div className="flex flex-1 mx-1">
            <Box sx={{ display: { xs: "none", md: "flex" }, mt: -1 }}>
              <div className="flex flex-1 mx-1">
                <Link to="/" underline="none">
                  <img src={logo1} className="font-bold h-24" alt="logo" />
                </Link>
              </div>
            </Box>
            <Button
              size="medium"
              variant="text"
              sx={{
                // mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              MRV
            </Button>
          </div>
        </div>

        <div className="col-span-2 self-right">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, ...(open === false && { display: "none" }) }}
            className="w-40 h-40"
          >
            <MenuIcon />
          </IconButton>
        </div>
      </div>

      <DrawerHead className="relative flex flex-col items-center justify-center shadow-0" />
    </div>
  );
};
