import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { backend_url } from "../../services/Constants";
import axios from "axios";
import MRVSnackbar from "../../components/MRVSnackbar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CalculateIcon from "@mui/icons-material/Calculate";
import Loading from "../../components/Loading";

export const MeasureCarbon = (props) => {
  const { token, project_id, project_title } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const schema = yup.object().shape({
    year_from: yup.string().required("This field is required"),
    year_to: yup.string().required("This field is required"),
  });

  const defaultValues = {
    year_from: "",
    year_to: "",
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function submit({ year_from, year_to }) {
    const carbonInfo = {
      // area: area,
      project: project_id,
      project_title: project_title,
      year1: year_from,
      year2: year_to,
    };

    axios
      .put(
        `${backend_url}/farm/carbon/`,
        carbonInfo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Calculated Carbon.");
        setLoading(false);
        handleClose();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.response.data.details);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button
        className="whitespace-nowrap"
        variant="contained"
        color="primary"
        startIcon={<CalculateIcon size={20} />}
        onClick={handleClickOpen}
      >
        Calculate Carbon
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography
            component="p"
            variant="p"
            className="px-4 py-16 text-sm md:text-md font-semibold sm:font-bold"
          >
            Calculate carbon
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Measure carbon for a specified year eg.(2024-2022).
          </DialogContentText>
          <form
            name="tripForm"
            noValidate
            className="flex flex-col justify-center w-full mt-8 sm:mt-8  md:min-w-320"
            onSubmit={handleSubmit(submit)}
          >
            {openMessage === true && (
              <MRVSnackbar
                openMessage={openMessage}
                severity={severity}
                message={message}
              />
            )}

            <div className="sm:flex my-4">
              <Controller
                name="year_to"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ m: 1 }}
                    id="year_to"
                    label="Year To"
                    type="number"
                    error={!!errors.year_to}
                    helperText={errors?.year_to?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                name="year_from"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ m: 1 }}
                    id="year_from"
                    label="Year From"
                    type="number"
                    error={!!errors.year_from}
                    helperText={errors?.year_from?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{ mx: 1 }}
                variant="contained"
                color="error"
                // disabled={_.isEmpty(dirtyFields) || !isValid}
              >
                Cancel
              </Button>
              <Button
                sx={{ mx: 1 }}
                variant="contained"
                color="success"
                // disabled={loading === true ? true : false}
                onClick={() => {
                  setOpenMessage(false);
                  setLoading(true);
                }}
                type="submit"
              >
                {loading === true ? <Loading /> : "Submit"}
              </Button>
            </DialogActions>
          </form>
          {loading === true && (
            <DialogContentText>
              <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0  ">
                <div className="flex flex-auto items-center min-w-0">
                  <div className="flex flex-col items-center min-w-0 px-2 sm:px-16 py-2 ">
                    <Loading />

                    <Typography
                      variant="p"
                      className="text-sm text-center font-normal  "
                    >
                      Calculating carbon change. This process will take some few
                      minutes. Do not quit the page.
                    </Typography>
                  </div>
                </div>
              </div>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
