import React, { useState } from "react";
import {
  Button,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import format from "date-fns/format";
import { countries } from "./countries";
import { backend_url } from "../../services/Constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Loading from "../../components/Loading";
import MRVSnackbar from "../../components/MRVSnackbar";

function UpdateProject(props) {
  const { token, data } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const countrys = countries && countries.map((countries) => countries.name);

  const schema = yup.object().shape({
    project_title: yup.string().required("This field is required"),
    description: yup.string().notRequired(),
    verification_status: yup.string().required("This field is required"),
    project_type: yup.string().required("This field is required"),
    country: yup
      .string()
      .required("You must enter the country")
      .oneOf(countrys !== false ? countrys : []),
    // hectares: yup.number().required("You must enter date of program"),
    start_date: yup.string().notRequired(),
    end_date: yup.string().notRequired(),
    State: yup.string().notRequired(),
  });

  const defaultValues = {
    project_title: data?.properties !== undefined ? data.properties.name : "",
    description:
      data?.properties !== undefined ? data.properties.description : "",
    // hectares:  data?.properties !== undefined ? data.properties.name : "",
    verification_status:
      data?.properties !== undefined ? data.properties.status : "",
    project_type: data?.properties !== undefined ? data.properties.type : "",
    country: data?.properties !== undefined ? data.properties.country : "",
    State: data?.properties !== undefined ? data.properties.State : "",
    start_date:
      data?.properties !== undefined ? dayjs(data.properties.start_date) : null,
    end_date:
      data?.properties !== undefined ? dayjs(data.properties.end_date) : null,
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;


  const proj_id = data.id;
  // const proj_geometry = data.geometry;

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenMessage(false);
    setLoading(false);
  };

  function submit({
    // hectares,
    description,
    verification_status,
    project_type,
    project_title,
    start_date,
    end_date,
    country,
    State,
  }) {
    const formData = new FormData();
    formData.append("id", proj_id);
    formData.append("name", project_title);
    // formData.append("hectares", hectares);
    formData.append("description", description);
    formData.append("status", verification_status);
    formData.append("type", project_type);
    formData.append("State", State);
    formData.append("country", country);
    formData.append("start_date", format(new Date(start_date), "yyyy-MM-dd"));
    formData.append("end_date", format(new Date(end_date), "yyyy-MM-dd"));
    // formData.append("geometry", proj_geometry);

    axios
      .put(
        `${backend_url}/farm/project/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Project Updated Successfully.");
        setLoading(false);
        handleCloseDialog();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage("Error updating project.");
        setLoading(false);
      });
  }

  return (
    <div>
      <Button variant="outlined" color="secondary" onClick={handleOpenDialog}>
        Update Project
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
        <form
          name="updateProjectDetails"
          noValidate
          onSubmit={handleSubmit(submit)}
        >
          <DialogTitle>Update Project Details</DialogTitle>
          <DialogContent>
            {openMessage === true && (
              <MRVSnackbar
                openMessage={openMessage}
                severity={severity}
                message={message}
              />
            )}

            <Controller
              name="project_title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Project Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.project_title}
                  helperText={errors?.project_title?.message}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  id="description"
                  variant="outlined"
                  sx={{ my: 2 }}
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  multiline
                  minRows={4}
                  fullWidth
                />
              )}
            />

            {/* <Controller
              name="hectares"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Hectares"
                  variant="outlined"
                  type="number"
                  fullWidth
                  margin="normal"
                  error={!!errors.hectares}
                  helperText={errors?.hectares?.message}
                />
              )}
            /> */}

            <Controller
              name="verification_status"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.verification_status}
                >
                  <InputLabel>Status</InputLabel>
                  <Select {...field} label="Status">
                    <MenuItem value="Notstarted">Not Started</MenuItem>
                    <MenuItem value="Ongoing">Ongoing</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.verification_status?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="w-full">
                <Controller
                  name="start_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{ mb: 3, mt: 2 }}
                      clearable
                      slotProps={{
                        textField: {
                          id: "from-date",
                          label: "Start Date",
                          error: errors.start_date,
                          helperText: errors?.start_date?.message,
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  )}
                />
              </div>

              <div className="w-full">
                <Controller
                  name="end_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{ mb: 3, mt: 2 }}
                      clearable
                      slotProps={{
                        textField: {
                          id: "to-date",
                          label: "End Date",
                          error: errors.end_date,
                          helperText: errors?.end_date?.message,
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>

            <Controller
              name="project_type"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.project_type}
                >
                  <InputLabel>Project Type</InputLabel>
                  <Select {...field} label="Project Type">
                    <MenuItem value="Agroforestry">Agroforestry</MenuItem>
                    <MenuItem value="Crop Sequestration">
                      Crop Sequestration
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.project_type?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  sx={{ p: 1 }}
                  id="country"
                  fullWidth
                  options={countrys}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      helperText={errors?.country?.message}
                      error={!!errors.country}
                      {...params}
                      label="Country"
                    />
                  )}
                />
              )}
            />

            <Controller
              name="State"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="State Region: "
                  id="State"
                  variant="outlined"
                  error={!!errors.State}
                  helperText={errors?.State?.message}
                  multiline
                  fullWidth
                />
              )}
            />

            {/* <Button
              variant="outlined"
              color="error"
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                setOpenMessage(false);
                setLoading(true);
              }}
            >
              {loading ? <Loading /> : "Save"}
            </Button> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                setOpenMessage(false);
                setLoading(true);
              }}
            >
              {loading ? <Loading /> : "Save"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default UpdateProject;
