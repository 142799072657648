import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";

function AcreageWidget({ summary }) {
  return (
    <Paper elevation={0} className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden  mb-8 py-4">
      <div className="text-center  px-8  ">
        <Typography
          variant="p"
          className="px-8 text-md font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          Total Hectarage
        </Typography>
      </div>
      <div className="text-center mt-8 flex flex-col">
        <Typography
          variant="p"
          className="text-xl sm:text-3xl font-bold tracking-tight leading-none text-red-500"
        >
          {summary.total_hectares !== undefined &&
          summary.total_hectares !== null
            ? summary.total_hectares.toFixed(2)
            : 0}
        </Typography>
        <Typography variant="p" className="text-md font-medium text-red-600">
          Ha
        </Typography>
      </div>
    </Paper>
  );
}

export default memo(AcreageWidget);
