import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MapKey = ({ activeClass, setActiveClass, counts }) => {

  // Dynamically create map key data from counts
  const mapKeyData = Object.keys(counts).map((key) => {
    let name;
    switch (key) {
      case "all":
        name = "all";
        break;
      case "not_set":
        name = "Not Set";
        break;
      default:
        name = `${key}`;
    }
    return {
      name,
      class: key === "all" ? "all" : key === 0 ? 0 : parseInt(key, 120),
      count: counts[key],
    };
  });

  const handleClassClick = (selectedClass) => {
    setActiveClass(selectedClass);
  };

  return (
    <div>
      <Accordion elevation={0} defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="p" className="text-sm font-bold">
            Map Key
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {mapKeyData.map((item, index) => (
            <Button
              key={index}
              color="primary"
              variant={activeClass === item.name ? "contained" : "outlined"}
              onClick={() => handleClassClick(item.name)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "8px",
                width: "100%",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body1">{item.name}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className="font-bold">
                    {item.count}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MapKey;
