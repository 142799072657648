import React, { useState, useEffect } from "react";
import { Card, CardContent, Paper, Typography } from "@mui/material";
import Chart from "react-apexcharts";

const TreeCarbonBarChart = ({ treeCarbon }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Carbon",
        data: [50, 60, 45, 70, 55], // Dummy data: [Moringa, Eucalyptus, etc.]
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Moringa", "Eucalyptus", "Oak", "Pine", "Baobab"],
        labels: {
          style: {
            colors: ["#05B050", "#05B050", "#05B050", "#05B050", "#05B050"],
            fontSize: "12px",
          },
        },
      },
      colors: ["#05B050", "#05B050", "#05B050", "#05B050", "#05B050"],
      title: {
        text: "Tree Carbon Content",
        align: "center",
        style: {
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
      yaxis: {
        title: {
          text: "Amount (MtCO2e)",
        },
      },
      legend: {
        show: false,
      },
    },
  });

  useEffect(() => {
    const processData = () => {
      const treeCarbonData = treeCarbon
        .map((tree_1) => {
          const tree_0 = treeCarbon.find(
            (tree) => tree.common_name === tree_1.common_name
          );
          if (tree_0) {
            return {
              common_name: tree_1.common_name,
              carbon_value: tree_1.carbon * 0.00367,
            };
          }
          return null;
        })
        .filter((tree) => tree !== null);

      setChartData((prevState) => ({
        ...prevState,
        series: [
          {
            name: "Carbon",
            data: treeCarbonData.map((tree) => tree.carbon_value.toFixed(2)),
          },
        ],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: treeCarbonData.map((tree) => tree.common_name),
          },
        },
      }));
    };

    processData();
  }, []);

  return (
    <Paper className="flex flex-col flex-auto max-h-256 p-24 mt-2 shadow rounded-2xl overflow-hidden my-4 ml-2">
      {/* <Typography variant="h5" component="div" gutterBottom>
          Tree Carbon Content
        </Typography> */}
      <div className="chart">
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
      </div>
    </Paper>
  );
};

export default TreeCarbonBarChart;
