import Paper from "@mui/material/Paper";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { memo } from "react";

function TotalArea({data}) {
  const theme = useTheme();

  const labels = [
    "Jan",
    "Feb",
  ];

  const series = [
    {
      name: "Series 1",
      data: [60, 70],
    },
  ];

  const chartOptions = {
    chart: {
      animations: {
        enabled: false,
      },
      fontFamily: "inherit",
      foreColor: "inherit",
      height: "100%",
      type: "area",
      sparkline: {
        enabled: true,
      },
    },
    colors: [theme.palette.primary.main],
    fill: {
      colors: [theme.palette.primary.light],
      opacity: 0.5,
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      followCursor: true,
      theme: "dark",
    },
    xaxis: {
      type: "category",
      categories: labels,
    },
  };
  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-start justify-between m-24 mb-0">
        <Typography
          variant="p"
          className="text-lg font-medium tracking-tight leading-6 truncate"
        >
          Total Area
        </Typography>
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center mx-24 mt-12">
        <Typography
          variant="p"
          className="text-3xl font-bold tracking-tighter leading-tight"
        >
          {data.properties.hectares.toFixed(2)} Ha
        </Typography>
        {/* <div className="flex lg:flex-col lg:ml-12">
          <TrendingDownIcon size={20} className="text-red-500" />
          <Typography
            variant="p"
            className="flex items-center ml-4 lg:ml-0 lg:mt-2 text-md leading-none whitespace-nowrap"
            color="text.secondary"
          >
            <span className="font-medium text-red-500">2%</span>
            <span className="ml-4">below target</span>
          </Typography>
        </div> */}
      </div>
      <div className="flex flex-col flex-auto h-80">
        <ReactApexChart
          options={chartOptions}
          series={series}
          type={chartOptions.chart.type}
          height={chartOptions.chart.height}
        />
      </div>
    </Paper>
  );
}

export default memo(TotalArea);
