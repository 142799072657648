import { useEffect } from "react";
import L from "leaflet";
import {
  Card,
  CardContent,
  FormControlLabel,
  // Button,
  Grid,
  Switch,
  ThemeProvider,
  Typography,
  // Typography,
  // FormControlLabel,
  // Switch,
} from "@mui/material";
import { createRoot } from "react-dom/client";
import { useMap } from "react-leaflet";
import theme from "../../../components/Theme";
import MapKey from "./MapKey";

const Legend = ({ setActiveClass, activeClass, counts, setRaster, raster }) => {
  const map = useMap();

  useEffect(() => {
    if (map) {
      const legend = L.control({ position: "topright" });

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        return div;
      };

      legend.addTo(map);

      const legendDiv = document.querySelector(".info.legend");
      if (legendDiv) {
        const root = createRoot(legendDiv);

        const handleRaster = (e) => {
          setRaster(e.target.checked);
        };

        root.render(
          <ThemeProvider theme={theme}>
            <Grid container spacing={2}>
              <Grid
                item
                style={{
                  // paddingTop: "240px",
                  maxHeight: "90vh",
                  width: "20vw",
                  overflowY: "auto",
                  paddingBottom: "240px",
                }}
              >
                {/* <Card className="my-8">
                  <CardContent className="-my-8">
                    <div className="flex flex-row items-center justify-between">
                      <Typography
                        variant="p"
                        className="text-left text-sm font-bold"
                      >
                        Carbon Map
                      </Typography>
                      <FormControlLabel
                        value="start"
                        control={
                          <Switch
                            checked={raster}
                            onChange={(e) => handleRaster(e)}
                            color="primary"
                          />
                        }
                        label=""
                        className="ml-auto" // Add margin-left auto to push it to the right
                        labelPlacement="start"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <Typography
                        variant="p"
                        component="p"
                        className="text-left text-sm font-bold"
                      >
                        NDVI Map
                      </Typography>
                      <FormControlLabel
                        value="start"
                        control={
                          <Switch
                            checked={raster}
                            onChange={(e) => handleRaster(e)}
                            color="primary"
                          />
                        }
                        label=""
                        className="ml-auto" // Add margin-left auto to push it to the right
                        labelPlacement="start"
                      />
                    </div>
                  </CardContent>
                </Card> */}

                <Card className="my-8">
                  <CardContent className="-m-8">
                    <MapKey
                      counts={counts}
                      activeClass={activeClass}
                      setActiveClass={setActiveClass}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </ThemeProvider>,
          legendDiv
        );
      }
    }
  }, [map, activeClass, raster]);

  return null;
};

export default Legend;
