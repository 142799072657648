import { useState } from "react";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "../@lodash";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Loading from "../components/Loading";
import logohome from "../assets/logohome.png";
import { backend_url } from "../services/Constants";
import MRVSnackbar from "../components/MRVSnackbar";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultValues = {
  password: "",
  passwordConfirm: "",
};

function ResetPassword() {
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const url = window.location.href;

  function getTrcyeihehValue(url) {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    return urlParams.get("trcyeiheh");
  }

  const trcyeiheh = getTrcyeihehValue(url);
  const navigate = useNavigate();

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function submit({ password, passwordConfirm }) {
    const resetForm = {
      password,
      re_password: passwordConfirm,
      trcyeiheh: trcyeiheh,
    };

    axios
      .post(
        `${backend_url}/auth/user/password_reset/`,
        resetForm,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Password updated successfully!");
        setLoading(false);
        reset(defaultValues);
        navigate("/sign-in");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.response.data?.error);
        setLoading(false);
      });
  }

  return (
    <Container component="main" maxWidth="xl">
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32 mt-56">
        <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
          {openMessage === true && (
            <MRVSnackbar
              openMessage={openMessage}
              severity={severity}
              message={message}
            />
          )}
          <div className="w-full sm:w-auto py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-256 sm:w-256 mx-auto sm:mx-0">
              <img className="w-full sm:w-70" src={logohome} alt="logo" />

              <Typography
                variant="p"
                component="p"
                className="flex flex-col pt-16 sm:mt-32 text-xl sm:text-2xl font-bold sm:font-extrabold tracking-tight leading-tight"
              >
                Reset your password
              </Typography>

              <div className="sm:flex items-baseline pt-6 text-xs sm:text-sm font-small sm:font-medium">
                Create a new password for your account
              </div>

              <form
                name="resetForm"
                noValidate
                className="flex flex-col justify-center w-full mt-16 sm:mt-32"
                onSubmit={handleSubmit(submit)}
              >
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Password"
                      type="password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="passwordConfirm"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      label="Password (Confirm)"
                      type="password"
                      error={!!errors.passwordConfirm}
                      helperText={errors?.passwordConfirm?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Button
                  variant="contained"
                  color="primary"
                  className=" w-full mt-4"
                  aria-label="Reset Password"
                  disabled={_.isEmpty(dirtyFields) || !isValid}
                  onClick={() => {
                    setLoading(Object.keys(errors).length > 0 ? false : true);
                  }}
                  type="submit"
                  size="large"
                >
                  {loading === true ? Object.keys(errors).length > 0 ? (setLoading(false)) : <Loading /> : "Reset your password"}
                </Button>

                <Typography
                  variant="p"
                  className="py-12 text-xs font-medium"
                  color="text.secondary"
                >
                  <span>Return to</span>
                  <Link className="ml-4 font-bold" to="/">
                    Sign In
                  </Link>
                </Typography>
              </form>
            </div>
          </div>

          <Box
            className="relative hidden md:flex flex-auto items-center justify-center min-h-full p-64 lg:px-112 overflow-hidden"
            sx={{ backgroundColor: "primary.dark" }}
          >
            <svg
              className="absolute inset-0 pointer-events-none"
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Box
                component="g"
                sx={{ color: "primary.light" }}
                className="opacity-20"
                fill="none"
                stroke="currentColor"
                strokeWidth="100"
              >
                <circle r="234" cx="196" cy="23" />
                <circle r="234" cx="790" cy="491" />
              </Box>
            </svg>
            <Box
              component="svg"
              className="absolute -top-64 -right-64 opacity-20"
              sx={{ color: "primary.light" }}
              viewBox="0 0 220 192"
              width="220px"
              height="192px"
              fill="none"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </Box>

            <div className="z-10 relative w-full max-w-2xl">
              <div className="text-7xl font-bold leading-none text-white text-center">
                <div>MRV</div>
                <div>System</div>
              </div>
            </div>
          </Box>
        </Paper>
      </div>
    </Container>
  );
}

export default ResetPassword;
