export const wetlandMarkup = `
<svg width="33" height="44" viewBox="0 0 33 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 43.05C22.299 43.05 27 40.6995 27 37.8C27 34.9005 22.299 32.55 16.5 32.55C10.701 32.55 6 34.9005 6 37.8C6 40.6995 10.701 43.05 16.5 43.05Z" fill="url(#paint0_radial_671_16186)"/>
<path d="M30 16.5C30 22.07 23.25 30 17.75 37.5C17.02 38.5 15.98 38.5 15.25 37.5C9.75 30 3 22.22 3 16.5C3 9.04 9.04 3 16.5 3C23.96 3 30 9.04 30 16.5Z" fill="#05B050"/>
<path opacity="0.25" d="M16.5 3C9.04 3 3 9.04 3 16.5C3 22.22 9.75 30 15.25 37.5C16 38.52 17.02 38.5 17.75 37.5C23.25 30 30 22.07 30 16.5C30 9.04 23.96 3 16.5 3ZM16.5 4C23.42 4 29 9.58 29 16.5C29 18.9 27.5 22.18 25.22 25.74C22.95 29.3 19.71 33.14 16.94 36.91C16.74 37.18 16.61 37.32 16.5 37.44C16.39 37.32 16.26 37.18 16.06 36.91C13.28 33.13 10.41 29.31 8.02 25.77C5.62 22.23 4 18.95 4 16.5C4 9.58 9.58 4 16.5 4Z" fill="black"/>
<path d="M32.5 16.5C32.5 25.3366 25.3366 32.5 16.5 32.5C7.66344 32.5 0.5 25.3366 0.5 16.5C0.5 7.66344 7.66344 0.5 16.5 0.5C25.3366 0.5 32.5 7.66344 32.5 16.5Z" fill="#008DDC" stroke="#05B050"/>
<path d="M15.8483 9.16448C15.8053 9.1129 15.7519 9.07149 15.6918 9.04309C15.6317 9.0147 15.5663 9 15.5002 9C15.434 9 15.3687 9.0147 15.3086 9.04309C15.2485 9.07149 15.1951 9.1129 15.152 9.16448C13.9428 10.6164 10 15.6358 10 19.354C10 22.8188 12.1251 25 15.5 25C18.8749 25 21 22.8188 21 19.354C21 15.6358 17.0572 10.6164 15.8483 9.16448ZM16.1111 22.9612C16.0377 22.9613 15.9653 22.9434 15.9001 22.9089C15.8348 22.8743 15.7786 22.8242 15.7362 22.7627C15.6937 22.7012 15.6663 22.6302 15.6562 22.5556C15.646 22.4809 15.6535 22.4049 15.678 22.3338C15.7092 22.2411 15.7682 22.1609 15.8464 22.1046C15.9247 22.0483 16.0182 22.0187 16.1138 22.0202C16.7205 22.0188 17.3019 21.7708 17.7309 21.3304C18.1599 20.8901 18.4015 20.2932 18.4028 19.6704C18.4014 19.5723 18.4302 19.4763 18.485 19.3959C18.5399 19.3156 18.618 19.2551 18.7083 19.223C18.7775 19.1979 18.8516 19.1902 18.9243 19.2006C18.997 19.211 19.0662 19.2392 19.1261 19.2828C19.186 19.3263 19.2349 19.384 19.2685 19.451C19.3021 19.518 19.3196 19.5923 19.3194 19.6676C19.3185 20.5409 18.9802 21.378 18.3787 21.9955C17.7773 22.6129 16.9617 22.9602 16.1111 22.9612Z" fill="white"/>
<defs>
<radialGradient id="paint0_radial_671_16186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 37.8) scale(10.5 5.25)">
<stop offset="0.1" stop-opacity="0.4"/>
<stop offset="1" stop-opacity="0.05"/>
</radialGradient>
</defs>
</svg>
`;

export const barelandMarkup = `
<svg width="33" height="44" viewBox="0 0 33 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 43.05C22.299 43.05 27 40.6995 27 37.8C27 34.9005 22.299 32.55 16.5 32.55C10.701 32.55 6 34.9005 6 37.8C6 40.6995 10.701 43.05 16.5 43.05Z" fill="url(#paint0_radial_671_16178)"/>
<path d="M30 16.5C30 22.07 23.25 30 17.75 37.5C17.02 38.5 15.98 38.5 15.25 37.5C9.75 30 3 22.22 3 16.5C3 9.04 9.04 3 16.5 3C23.96 3 30 9.04 30 16.5Z" fill="#05B050"/>
<path opacity="0.25" d="M16.5 3C9.04 3 3 9.04 3 16.5C3 22.22 9.75 30 15.25 37.5C16 38.52 17.02 38.5 17.75 37.5C23.25 30 30 22.07 30 16.5C30 9.04 23.96 3 16.5 3ZM16.5 4C23.42 4 29 9.58 29 16.5C29 18.9 27.5 22.18 25.22 25.74C22.95 29.3 19.71 33.14 16.94 36.91C16.74 37.18 16.61 37.32 16.5 37.44C16.39 37.32 16.26 37.18 16.06 36.91C13.28 33.13 10.41 29.31 8.02 25.77C5.62 22.23 4 18.95 4 16.5C4 9.58 9.58 4 16.5 4Z" fill="black"/>
<path d="M32.5 16.5C32.5 25.3366 25.3366 32.5 16.5 32.5C7.66344 32.5 0.5 25.3366 0.5 16.5C0.5 7.66344 7.66344 0.5 16.5 0.5C25.3366 0.5 32.5 7.66344 32.5 16.5Z" fill="#FF814A" stroke="#05B050"/>
<path d="M15.3291 21.9915H21V25H11V9H15.3291V21.9915Z" fill="black"/>
<defs>
<radialGradient id="paint0_radial_671_16178" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 37.8) scale(10.5 5.25)">
<stop offset="0.1" stop-opacity="0.4"/>
<stop offset="1" stop-opacity="0.05"/>
</radialGradient>
</defs>
</svg>
`;

export const builtUpMarkup = `
<svg width="33" height="44" viewBox="0 0 33 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 43.05C22.299 43.05 27 40.6995 27 37.8C27 34.9005 22.299 32.55 16.5 32.55C10.701 32.55 6 34.9005 6 37.8C6 40.6995 10.701 43.05 16.5 43.05Z" fill="url(#paint0_radial_671_16161)"/>
<path d="M30 16.5C30 22.07 23.25 30 17.75 37.5C17.02 38.5 15.98 38.5 15.25 37.5C9.75 30 3 22.22 3 16.5C3 9.04 9.04 3 16.5 3C23.96 3 30 9.04 30 16.5Z" fill="#05B050"/>
<path opacity="0.25" d="M16.5 3C9.04 3 3 9.04 3 16.5C3 22.22 9.75 30 15.25 37.5C16 38.52 17.02 38.5 17.75 37.5C23.25 30 30 22.07 30 16.5C30 9.04 23.96 3 16.5 3ZM16.5 4C23.42 4 29 9.58 29 16.5C29 18.9 27.5 22.18 25.22 25.74C22.95 29.3 19.71 33.14 16.94 36.91C16.74 37.18 16.61 37.32 16.5 37.44C16.39 37.32 16.26 37.18 16.06 36.91C13.28 33.13 10.41 29.31 8.02 25.77C5.62 22.23 4 18.95 4 16.5C4 9.58 9.58 4 16.5 4Z" fill="black"/>
<path d="M32.5 16.5C32.5 25.3366 25.3366 32.5 16.5 32.5C7.66344 32.5 0.5 25.3366 0.5 16.5C0.5 7.66344 7.66344 0.5 16.5 0.5C25.3366 0.5 32.5 7.66344 32.5 16.5Z" fill="#CC3BFF" stroke="#05B050"/>
<path d="M22.3 14.6706V9.05882H19.6V11.8118L16 8L7 17.5294H9.7V26H14.2V19.6471H17.8V26H22.3V17.5294H25L22.3 14.6706ZM14.2 15.4118C14.2 14.2471 15.01 13.2941 16 13.2941C16.99 13.2941 17.8 14.2471 17.8 15.4118H14.2Z" fill="white"/>
<defs>
<radialGradient id="paint0_radial_671_16161" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 37.8) scale(10.5 5.25)">
<stop offset="0.1" stop-opacity="0.4"/>
<stop offset="1" stop-opacity="0.05"/>
</radialGradient>
</defs>
</svg>
`;

export const croplandMarkup = `
<svg width="33" height="44" viewBox="0 0 33 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 43.05C22.299 43.05 27 40.6995 27 37.8C27 34.9005 22.299 32.55 16.5 32.55C10.701 32.55 6 34.9005 6 37.8C6 40.6995 10.701 43.05 16.5 43.05Z" fill="url(#paint0_radial_671_16153)"/>
<path d="M30 16.5C30 22.07 23.25 30 17.75 37.5C17.02 38.5 15.98 38.5 15.25 37.5C9.75 30 3 22.22 3 16.5C3 9.04 9.04 3 16.5 3C23.96 3 30 9.04 30 16.5Z" fill="#05B050"/>
<path opacity="0.25" d="M16.5 3C9.04 3 3 9.04 3 16.5C3 22.22 9.75 30 15.25 37.5C16 38.52 17.02 38.5 17.75 37.5C23.25 30 30 22.07 30 16.5C30 9.04 23.96 3 16.5 3ZM16.5 4C23.42 4 29 9.58 29 16.5C29 18.9 27.5 22.18 25.22 25.74C22.95 29.3 19.71 33.14 16.94 36.91C16.74 37.18 16.61 37.32 16.5 37.44C16.39 37.32 16.26 37.18 16.06 36.91C13.28 33.13 10.41 29.31 8.02 25.77C5.62 22.23 4 18.95 4 16.5C4 9.58 9.58 4 16.5 4Z" fill="black"/>
<path d="M32.5 16.5C32.5 25.3366 25.3366 32.5 16.5 32.5C7.66344 32.5 0.5 25.3366 0.5 16.5C0.5 7.66344 7.66344 0.5 16.5 0.5C25.3366 0.5 32.5 7.66344 32.5 16.5Z" fill="#11570B" stroke="#05B050"/>
<path d="M23.0936 19.6427C22.2146 20.3106 21.205 20.6619 20.1764 20.6577C19.3146 20.6492 18.4625 20.4289 17.6688 20.0093C17.057 21.0889 16.7296 22.3799 16.7321 23.7029V27.0815C16.7323 27.2071 16.7119 27.3315 16.672 27.4468C16.6322 27.5622 16.5738 27.666 16.5005 27.752C16.4272 27.8379 16.3405 27.9041 16.2459 27.9465C16.1512 27.9888 16.0506 28.0063 15.9503 27.998C15.7619 27.9775 15.5866 27.8687 15.4596 27.6934C15.3326 27.5181 15.2634 27.2893 15.2657 27.0528V25.6276L11.7261 21.2031C11.2 21.4484 10.6435 21.5768 10.0819 21.5823C9.30878 21.5848 8.55013 21.3203 7.8896 20.8181C5.89252 19.3013 4.81745 15.8104 5.0255 11.4764C5.03597 11.2522 5.11195 11.0406 5.239 10.8817C5.36605 10.7229 5.53534 10.6279 5.71471 10.6148C9.18188 10.3594 11.9745 11.6986 13.1843 14.195C13.6596 15.1735 13.8671 16.3189 13.7754 17.4579C13.7697 17.5461 13.7437 17.6304 13.7005 17.7006C13.6573 17.7707 13.5988 17.8237 13.532 17.8532C13.4652 17.8826 13.393 17.8873 13.3241 17.8665C13.2553 17.8458 13.1927 17.8005 13.144 17.7363L11.3843 15.4335C11.2456 15.2689 11.0611 15.1785 10.8699 15.1815C10.6787 15.1846 10.496 15.2809 10.3608 15.4499C10.2256 15.6189 10.1485 15.8472 10.1461 16.0862C10.1437 16.3252 10.216 16.556 10.3477 16.7292L15.2859 23.059C15.2914 22.9697 15.2978 22.8803 15.3051 22.7921C15.4654 21.0928 16.0653 19.5054 17.0135 18.2713L21.6501 12.1466C21.7877 11.9748 21.865 11.7417 21.8651 11.4986C21.8652 11.2555 21.788 11.0223 21.6506 10.8503C21.5131 10.6783 21.3266 10.5816 21.1322 10.5815C20.9377 10.5814 20.7511 10.6779 20.6135 10.8497L16.1226 16.7865C16.0777 16.8461 16.0208 16.8894 15.9579 16.9122C15.895 16.9349 15.8284 16.9362 15.7649 16.9158C15.7015 16.8955 15.6436 16.8543 15.5972 16.7965C15.5508 16.7387 15.5177 16.6664 15.5012 16.5872C15.0668 14.5846 15.2584 12.5911 16.0878 10.8795C17.7247 7.50208 21.5337 5.69423 26.2776 6.04251C26.457 6.0556 26.6263 6.15057 26.7533 6.30939C26.8804 6.46821 26.9563 6.67982 26.9668 6.90405C27.2418 12.8351 25.7955 17.5965 23.0936 19.6427Z" fill="white"/>
<defs>
<radialGradient id="paint0_radial_671_16153" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 37.8) scale(10.5 5.25)">
<stop offset="0.1" stop-opacity="0.4"/>
<stop offset="1" stop-opacity="0.05"/>
</radialGradient>
</defs>
</svg>
`;

export const forestMarkup = `
<svg width="33" height="44" viewBox="0 0 33 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 43.05C22.299 43.05 27 40.6995 27 37.8C27 34.9005 22.299 32.55 16.5 32.55C10.701 32.55 6 34.9005 6 37.8C6 40.6995 10.701 43.05 16.5 43.05Z" fill="url(#paint0_radial_671_16194)"/>
<path d="M30 16.5C30 22.07 23.25 30 17.75 37.5C17.02 38.5 15.98 38.5 15.25 37.5C9.75 30 3 22.22 3 16.5C3 9.04 9.04 3 16.5 3C23.96 3 30 9.04 30 16.5Z" fill="#05B050"/>
<path opacity="0.25" d="M16.5 3C9.04 3 3 9.04 3 16.5C3 22.22 9.75 30 15.25 37.5C16 38.52 17.02 38.5 17.75 37.5C23.25 30 30 22.07 30 16.5C30 9.04 23.96 3 16.5 3ZM16.5 4C23.42 4 29 9.58 29 16.5C29 18.9 27.5 22.18 25.22 25.74C22.95 29.3 19.71 33.14 16.94 36.91C16.74 37.18 16.61 37.32 16.5 37.44C16.39 37.32 16.26 37.18 16.06 36.91C13.28 33.13 10.41 29.31 8.02 25.77C5.62 22.23 4 18.95 4 16.5C4 9.58 9.58 4 16.5 4Z" fill="black"/>
<path d="M32.5 16.5C32.5 25.3366 25.3366 32.5 16.5 32.5C7.66344 32.5 0.5 25.3366 0.5 16.5C0.5 7.66344 7.66344 0.5 16.5 0.5C25.3366 0.5 32.5 7.66344 32.5 16.5Z" fill="#120600" stroke="#05B050"/>
<path d="M17.2083 25V22.45H20.0417V25H17.2083ZM12.9583 25V21.6H8L10.7271 16.5H9.41667L14.375 8L19.3333 16.5H18.0229L20.7677 21.6H15.7917V25H12.9583ZM21.6354 21.6L19.3333 17.35H20.6969L16.9427 10.9113L18.625 8L23.5833 16.5H22.2729L25 21.6H21.6354Z" fill="white"/>
<defs>
<radialGradient id="paint0_radial_671_16194" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 37.8) scale(10.5 5.25)">
<stop offset="0.1" stop-opacity="0.4"/>
<stop offset="1" stop-opacity="0.05"/>
</radialGradient>
</defs>
</svg>
`;

export const grasslandMarkup = `
<svg width="33" height="44" viewBox="0 0 33 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 43.05C22.299 43.05 27 40.6995 27 37.8C27 34.9005 22.299 32.55 16.5 32.55C10.701 32.55 6 34.9005 6 37.8C6 40.6995 10.701 43.05 16.5 43.05Z" fill="url(#paint0_radial_671_16135)"/>
<path d="M30 16.5C30 22.07 23.25 30 17.75 37.5C17.02 38.5 15.98 38.5 15.25 37.5C9.75 30 3 22.22 3 16.5C3 9.04 9.04 3 16.5 3C23.96 3 30 9.04 30 16.5Z" fill="#05B050"/>
<path opacity="0.25" d="M16.5 3C9.04 3 3 9.04 3 16.5C3 22.22 9.75 30 15.25 37.5C16 38.52 17.02 38.5 17.75 37.5C23.25 30 30 22.07 30 16.5C30 9.04 23.96 3 16.5 3ZM16.5 4C23.42 4 29 9.58 29 16.5C29 18.9 27.5 22.18 25.22 25.74C22.95 29.3 19.71 33.14 16.94 36.91C16.74 37.18 16.61 37.32 16.5 37.44C16.39 37.32 16.26 37.18 16.06 36.91C13.28 33.13 10.41 29.31 8.02 25.77C5.62 22.23 4 18.95 4 16.5C4 9.58 9.58 4 16.5 4Z" fill="black"/>
<path d="M32.5 16.5C32.5 25.3366 25.3366 32.5 16.5 32.5C7.66344 32.5 0.5 25.3366 0.5 16.5C0.5 7.66344 7.66344 0.5 16.5 0.5C25.3366 0.5 32.5 7.66344 32.5 16.5Z" fill="#58FF9B" stroke="#05B050"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.8264 8.96543C10.9738 8.84127 11.1566 8.76673 11.3488 8.75241C11.541 8.73809 11.7328 8.78473 11.897 8.88568C15.065 10.8354 16.8012 13.3022 17.727 16.0174C17.9562 16.6893 18.1349 17.374 18.2734 18.067C19.2166 16.4812 20.7236 15.2235 22.9933 14.316C23.1533 14.2521 23.3281 14.2346 23.4977 14.2655C23.6672 14.2964 23.8246 14.3745 23.9518 14.4908C24.0789 14.607 24.1708 14.7568 24.2167 14.9229C24.2627 15.089 24.2609 15.2647 24.2115 15.4298L24.0456 15.9834C22.7989 20.1341 22.4167 21.4055 22.4167 24.3334C22.4167 24.5765 22.3201 24.8096 22.1482 24.9815C21.9763 25.1534 21.7431 25.25 21.5 25.25H10.5C10.2569 25.25 10.0238 25.1534 9.85185 24.9815C9.67994 24.8096 9.58337 24.5765 9.58337 24.3334C9.58337 20.7914 9.1507 18.5996 7.79678 14.5397C7.74168 14.3739 7.73511 14.1958 7.77784 14.0264C7.82057 13.857 7.91083 13.7033 8.03797 13.5835C8.16512 13.4637 8.32387 13.3827 8.49551 13.35C8.66715 13.3174 8.84454 13.3345 9.00678 13.3994C10.2855 13.9099 11.2572 14.5919 11.9905 15.3977C11.7451 13.5481 11.2665 11.737 10.566 10.0077C10.4943 9.82866 10.4808 9.63156 10.5275 9.44443C10.5743 9.2573 10.6788 9.08968 10.8264 8.96543Z" fill="#222222"/>
<defs>
<radialGradient id="paint0_radial_671_16135" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 37.8) scale(10.5 5.25)">
<stop offset="0.1" stop-opacity="0.4"/>
<stop offset="1" stop-opacity="0.05"/>
</radialGradient>
</defs>
</svg>
`;

export const defaultMarkup = `
<svg width="33" height="44" viewBox="0 0 33 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 43.05C22.299 43.05 27 40.6995 27 37.8C27 34.9005 22.299 32.55 16.5 32.55C10.701 32.55 6 34.9005 6 37.8C6 40.6995 10.701 43.05 16.5 43.05Z" fill="url(#paint0_radial_671_16178)"/>
<path d="M30 16.5C30 22.07 23.25 30 17.75 37.5C17.02 38.5 15.98 38.5 15.25 37.5C9.75 30 3 22.22 3 16.5C3 9.04 9.04 3 16.5 3C23.96 3 30 9.04 30 16.5Z" fill="#FF0000"/>
<path opacity="1" d="M16.5 3C9.04 3 3 9.04 3 16.5C3 22.22 9.75 30 15.25 37.5C16 38.52 17.02 38.5 17.75 37.5C23.25 30 30 22.07 30 16.5C30 9.04 23.96 3 16.5 3ZM16.5 4C23.42 4 29 9.58 29 16.5C29 18.9 27.5 22.18 25.22 25.74C22.95 29.3 19.71 33.14 16.94 36.91C16.74 37.18 16.61 37.32 16.5 37.44C16.39 37.32 16.26 37.18 16.06 36.91C13.28 33.13 10.41 29.31 8.02 25.77C5.62 22.23 4 18.95 4 16.5C4 9.58 9.58 4 16.5 4Z" fill="white"/>
<defs>
<radialGradient id="paint0_radial_671_16178" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 37.8) scale(10.5 5.25)">
<stop offset="0.1" stop-opacity="0.4"/>
<stop offset="1" stop-opacity="0.05"/>
</radialGradient>
</defs>
</svg>
`;

export const setMarkup = `
<svg width="33" height="44" viewBox="0 0 33 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 43.05C22.299 43.05 27 40.6995 27 37.8C27 34.9005 22.299 32.55 16.5 32.55C10.701 32.55 6 34.9005 6 37.8C6 40.6995 10.701 43.05 16.5 43.05Z" fill="url(#paint0_radial_671_16178)"/>
<path d="M30 16.5C30 22.07 23.25 30 17.75 37.5C17.02 38.5 15.98 38.5 15.25 37.5C9.75 30 3 22.22 3 16.5C3 9.04 9.04 3 16.5 3C23.96 3 30 9.04 30 16.5Z" fill="#05B050"/>
<path opacity="1" d="M16.5 3C9.04 3 3 9.04 3 16.5C3 22.22 9.75 30 15.25 37.5C16 38.52 17.02 38.5 17.75 37.5C23.25 30 30 22.07 30 16.5C30 9.04 23.96 3 16.5 3ZM16.5 4C23.42 4 29 9.58 29 16.5C29 18.9 27.5 22.18 25.22 25.74C22.95 29.3 19.71 33.14 16.94 36.91C16.74 37.18 16.61 37.32 16.5 37.44C16.39 37.32 16.26 37.18 16.06 36.91C13.28 33.13 10.41 29.31 8.02 25.77C5.62 22.23 4 18.95 4 16.5C4 9.58 9.58 4 16.5 4Z" fill="white"/>
<defs>
<radialGradient id="paint0_radial_671_16178" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 37.8) scale(10.5 5.25)">
<stop offset="0.1" stop-opacity="0.4"/>
<stop offset="1" stop-opacity="0.05"/>
</radialGradient>
</defs>
</svg>
`;
