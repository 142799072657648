import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Chip, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import Loading from "../../components/Loading";
import DeleteIcon from "@mui/icons-material/Delete";
import { backend_url } from "../../services/Constants";
import axios from "axios";
import MRVSnackbar from "../../components/MRVSnackbar";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Project Name",
  },
  {
    id: "hectares",
    numeric: true,
    disablePadding: false,
    label: "Hectares",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Dates",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Verification Status",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, setAPISort, apiSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleSort = (id) => {
    if (apiSort === id) {
      setAPISort(`-${id}`); // Deselect the column
    } else {
      setAPISort(id); // Select the column
    }
  };

  return (
    <TableHead sx={{ backgroundColor: "#29333F" }}>
      <TableRow className="flex rounded-full">
        <TableCell
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
            fontSize: "15px",
            color: "#fff",
          }}
        >
          S/N
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: "bold", fontSize: "15px", color: "#fff" }}
            onClick={() => headCell.id !== "date" && handleSort(headCell.id)}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: "#fff !important" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* {user.is_superuser === true && ( */}
        <TableCell
          align={"right"}
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
            fontSize: "15px",
            color: "#fff",
          }}
        >
          More
        </TableCell>
        {/* )} */}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar() {
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
      }}
      className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-24 px-24 md:px-32"
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="p"
        id="tableTitle"
        className="flex text-md font-semibold"
      >
        My Projects
      </Typography>
    </Toolbar>
  );
}

export default function ReportsWidget(props) {
  const { project_data, setAPISort, apiSort, token, user, count, setOffset } =
    props;

  const [loading, setLoading] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");

  const navigate = useNavigate();

  function deleteProject(project_id) {
    axios
      .delete(
        `${backend_url}/farm/project/?` +
          new URLSearchParams({
            // project_id: project_id,
            project_id: project_id,
          }),
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Project Deleted Successfully!");
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        // setMessage(err.response.data);
        setMessage(
          err.response.data?.error
            ? err.response.data?.details
            : err.response.data
        );
        setLoading(false);
      });
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("size");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = project_data.features.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - project_data.features.length)
      : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(project_data.features, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {openMessage === true && (
          <MRVSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
          />
        )}

        <EnhancedTableToolbar />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            {project_data.features !== undefined ? (
              <EnhancedTableHead
                setAPISort={setAPISort}
                apiSort={apiSort}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={project_data.features.length}
                user={user}
              />
            ) : (
              <Loading />
            )}
            <TableBody>
              {project_data.features !== undefined &&
                project_data.features.map((project, index) => {
                  const isItemSelected = isSelected(project.id);
                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={project.id}
                      selected={isItemSelected}
                      onClick={() => navigate(`/report/${project.id}`)}
                      sx={{
                        py: 2,
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)" }}
                      >
                        {(index += 1)}
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)" }}
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        {project.properties.name}
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)" }}
                        align="right"
                      >
                        {project.properties.hectares.toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)" }}
                        align="right"
                      >
                        {moment(project.properties.date).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)" }}
                        variant="p"
                        align="right"
                      >
                        <Chip
                          size="small"
                          icon={
                            <CircleIcon
                              sx={{ width: "12px" }}
                              color="inherit"
                            />
                          }
                          label={
                            project.properties.status === "Notstarted"
                              ? "Not started"
                              : project.properties.status
                          }
                          variant="contained"
                          color={
                            project.properties.status === "Ongoing"
                              ? "secondary"
                              : project.properties.status === "Completed"
                              ? "success"
                              : "warning"
                          }
                        />
                      </TableCell>
                      {/* {user.is_superuser === true && ( */}
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                        variant="p"
                        align="right"
                      >
                        <IconButton
                          variant="contained"
                          color="error"
                          type="submit"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent triggering the row click
                            deleteProject(project.id);
                          }}
                        >
                          {loading === true ? <Loading /> : <DeleteIcon />}
                        </IconButton>
                      </TableCell>
                      {/* )} */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)" }}
                    colSpan={6}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {project_data.features !== undefined && (
          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}
