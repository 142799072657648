import React, { useState } from "react";
import { Box, Typography, IconButton, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { lighten } from "@mui/material/styles";
import { backend_url } from "../../services/Constants";
import axios from "axios";
import MRVSnackbar from "../../components/MRVSnackbar";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../../components/Loading";

export const TrainingData = (props) => {
  const { token, project_id } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const todayAtMid = dayjs().set("hour", 0).startOf("hour");

  const schema = yup.object().shape({
    spatial_upload: yup.mixed().required("Please upload your leave letter"),
  });

  const defaultValues = {
    spatial_upload: null,
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submit({ spatial_upload }) {
    const formData = new FormData();
    // const fromDate = moment(date_from).format('YYYY-MM-DD');

    // Check if the file extension is ".geojson"
    const fileExtension = spatial_upload.name.split(".").pop().toLowerCase();
    if (fileExtension === "geojson") {
      // Append GeoJSON file to the FormData object
      formData.append("file", spatial_upload);
    } else {
      // Assume spatial_upload is an object containing Shapefile files
      formData.append("shp_file", spatial_upload.shp);
      formData.append("shx_file", spatial_upload.shx);
      formData.append("dbf_file", spatial_upload.dbf);
      // Add more append calls for additional files if needed
    }

    formData.append("project", project_id);

    axios
      .put(
        `${backend_url}/farm/training_data/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Training Data Uploaded Successfully.");
        setLoading(false);
        handleClose();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.response.data.details);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button
        className="whitespace-nowrap"
        variant="contained"
        color="primary"
        startIcon={<AddIcon size={20} />}
        onClick={handleClickOpen}
      >
        Add Training Data
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography
            component="p"
            variant="p"
            className="px-4 py-16 text-sm md:text-md font-semibold sm:font-bold"
          >
            Training Data
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add training data to support your model. The file should have the
            following fields, geometry, class type, class value, status and
            training date
          </DialogContentText>
          <form
            name="trainingForm"
            noValidate
            className="flex flex-col justify-center w-full mt-8 sm:mt-8"
            onSubmit={handleSubmit(submit)}
          >
            {openMessage === true && (
              <MRVSnackbar
                openMessage={openMessage}
                severity={severity}
                message={message}
              />
            )}
            <div className="sm:flex sm:-mx-4 mt-8">
              <Controller
                control={control}
                name="spatial_upload"
                render={({ field: { onChange, value } }) => (
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark"
                          ? lighten(theme.palette.background.default, 1)
                          : lighten(theme.palette.background.default, 0.02),
                    }}
                    component="label"
                    htmlFor="button_spatial_upload"
                    className="flex items-center justify-center relative w-full h-128 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                  >
                    <input
                      accept=".geojson, .shp, .shx, .dbf,"
                      className="hidden"
                      id="button_spatial_upload"
                      type="file"
                      onChange={(e) => onChange(e.target.files[0])}
                    />
                    <IconButton>
                      <UploadFileIcon
                        sx={{ color: "action" }}
                        fontSize="large"
                      />
                    </IconButton>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="p"
                        component="p"
                        className="text-sm font-small pl-8 pr-16"
                      >
                        {value === null ? (
                          <span>
                            {" "}
                            Drag any geographic file or cick to upload from
                            disk.
                          </span>
                        ) : (
                          value.name
                        )}
                      </Typography>

                      <Typography
                        variant="caption"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        Use any format that allows to describe geometries e.g
                        SHP, GeoJSON, KMl
                        {/* {value === null ? (
                              <span> Leave Upload</span>
                            ) : (
                              value.name
                            )} */}
                      </Typography>
                    </div>
                    <FormHelperText>
                      <span className="text-red-500">
                        {" "}
                        {errors?.spatial_upload?.message}
                      </span>
                    </FormHelperText>
                  </Box>
                )}
              />
            </div>

            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{ mx: 1 }}
                variant="contained"
                color="error"
              >
                Cancel
              </Button>
              <Button
                sx={{ mx: 1 }}
                variant="contained"
                color="success"
                // disabled={_.isEmpty(dirtyFields) || !isValid}
                onClick={() => {
                  setLoading(true);
                  setOpenMessage(false);
                }}
                type="submit"
              >
               {loading === true ? ( <Loading />) : ( "Submit" ) }
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
