import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import Chart from "react-apexcharts";

const SoilCarbonTable = () => {
  const [data, setData] = useState({
    location: "25 points",
    carbon: 20,
    texture: "Loamy",
  }); // Dummy data

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Carbon",
        data: [20], // Dummy data: Carbon content
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["25 points"],
        labels: {
          style: {
            colors: ["#FF4560"],
            fontSize: "12px",
          },
        },
      },
      colors: ["#FF4560"],
      title: {
        text: "Average Soil Carbon Content",
        align: "center",
        style: {
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
      yaxis: {
        title: {
          text: "Carbon Content",
        },
      },
      legend: {
        show: false,
      },
    },
  });

  // Simulate an API call
  useEffect(() => {
    const fetchData = async () => {
      // Replace this with your actual API call
      const response = {
        location: "25 points",
        carbon: 20,
        texture: "Loamy",
      }; // Replace this with actual data from the API
      setData(response);
      setChartData((prevState) => ({
        ...prevState,
        series: [{ ...prevState.series[0], data: [response.carbon] }],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: [response.location],
          },
        },
      }));
    };

    fetchData();
  }, []);

  return (

    <div>
      <Card className="flex flex-col flex-auto p-24 min-h-256 mt-2 shadow rounded-2xl overflow-hidden my-4 ml-2">
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Soil Carbon
          </Typography>
          <Typography variant="body1">
            <strong>Soil carbon points:</strong> {data.location}
          </Typography>
          <Typography variant="body1">
            {/* <strong>Carbon Content:</strong> {data.carbon} */}
            <strong>Carbon Content:</strong> No Data
          </Typography>
          {/* <Typography variant="body1">
            <strong>Texture:</strong> {data.texture}
          </Typography> */}
        </CardContent>
      </Card>
    </div>
  );
};

export default SoilCarbonTable;
