import React, { useEffect, useState, useRef } from "react";
import { Container, Grid, TableContainer, Typography } from "@mui/material";
import MRVSnackbar from "../../components/MRVSnackbar";
import { useParams } from "react-router-dom";
import { backend_url, googleMapsApiKey } from "../../services/Constants";
import DownloadReportButton from "./DownloadReport";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import "react-leaflet-fullscreen/styles.css";
import "../../styles/leaf.css";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "./ShareReport.css"; // Import the CSS file
import logohome from "../../assets/logohome.png";

export default function ShareReport(props) {
  const { token } = props;
  const [share, setShare] = useState(undefined);
  const [openMessage, setOpenMessage] = useState(false);
  const { id } = useParams();
  const reportRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/share/?` +
          new URLSearchParams({
            season_id: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) =>
          data.details === "['“all” is not a valid UUID.']"
            ? setOpenMessage(true)
            : setShare(data)
        );
    }

    fetchData();
  }, [token]);

  const capitalizeFirstLetter = (str) => {
    return str
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const renderData = (data, spacing) =>
    data !== undefined && (
      <Grid container spacing={2}>
        {Object.entries(data).map(([key, value]) => (
          <Grid
            item
            xs={spacing !== undefined ? spacing : 6}
            sm={spacing !== undefined ? spacing : 6}
            key={key}
          >
            <Typography variant="body1" component="h5">
              <strong>{capitalizeFirstLetter(key)}:</strong>
              {typeof value === "object" && value !== null ? (
                <Typography
                  // variant="body1"
                  // component="h5"
                  style={{ marginLeft: "15px", marginTop: "5px" }}
                >
                  {renderData(value, 12)}
                </Typography>
              ) : (
                `  ${value.toString()}`
              )}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );

  const defaultValue = [];

  const multiPolygon = share?.project_data?.coordinates || defaultValue;

  const reversedCoordinates =
    multiPolygon &&
    multiPolygon.map((coordPair) =>
      coordPair.map((coord) => [coord[1], coord[0]])
    );

  const centerCoord = multiPolygon.length
    ? [multiPolygon[0][0][1], multiPolygon[0][0][0]]
    : [0, 0];

  return (
    <Container className="container my-24">
      {openMessage === true && (
        <MRVSnackbar
          openMessage={openMessage}
          severity={"success"}
          message={"..."}
        />
      )}

      {share !== undefined && (
        <>
          <DownloadReportButton share={share} reportRef={reportRef} />
          <TableContainer ref={reportRef} id="pdfdiv" className="txt">
            <div>
              <div className="w-full max-w-192 sm:w-192 mx-auto flex items-center justify-center">
                <img className="w-70" src={logohome} alt="logo" />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "15px",
                }}
              >
                {share.project_data !== undefined &&
                  Object.entries(share.project_data).map(([key, value]) =>
                    key === "project_name" && key !== "coordinates" ? (
                      <div className="flexBasis" key={key}>
                        <Typography variant="body1" component="h5">
                          <h1> {value} Report</h1>
                        </Typography>
                      </div>
                    ) : null
                  )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-20px",
                    marginBottom: "20px",
                  }}
                >
                  <h2> Season Information from </h2>
                  {share.project_data !== undefined &&
                    Object.entries(share.project_data).map(
                      ([key, value]) =>
                        key === "start_date" && (
                          <h2
                            style={{ paddingLeft: "5px", paddingRight: "5px" }}
                          >
                            {`${key === "start_date" && value} `}{" "}
                          </h2>
                        )
                    )}
                  <h2>to</h2>
                  {share.project_data !== undefined &&
                    Object.entries(share.project_data).map(
                      ([key, value]) =>
                        key === "end_date" && (
                          <h2 style={{ paddingLeft: "5px" }}>
                            {`${key === "end_date" && value} `}{" "}
                          </h2>
                        )
                    )}
                </div>
              </div>

              <div className="section">
                <h2>Project Data</h2>
                <div className="flexWrap">
                  {share.project_data !== undefined &&
                    Object.entries(share.project_data).map(([key, value]) =>
                      (key === "project_name" ||
                        key === "hectares" ||
                        key === "start_date" ||
                        key === "end_date") &&
                      key !== "coordinates" ? (
                        <div className="flexBasis" key={key}>
                          <Typography variant="body1" component="h5">
                            <strong>
                              {key === "project_name" && "Project Name:"}
                              {key === "hectares" && "Hectares:"}
                              {key === "start_date" && "Start Date:"}
                              {key === "end_date" && "End Date:"}
                            </strong>{" "}
                            <span>
                              {" "}
                              {key === "hectares"
                                ? `${value.toFixed(2)} Ha`
                                : value}
                            </span>
                          </Typography>
                        </div>
                      ) : null
                    )}
                </div>
              </div>

              <div className="section">
                <h2>Crop Carbon Data</h2>
                <Grid container spacing={2}>
                  {share?.crop_carbon_data &&
                    Object.entries(share.crop_carbon_data).map(
                      ([cropName, data]) => (
                        <Grid item xs={6} sm={6} key={cropName}>
                          <div className="flexBasis" key={cropName}>
                            <Typography variant="body1" component="h5">
                              <span>
                                <strong>Crop Name:</strong> {cropName}
                              </span>
                              <br />
                              {data.carbon_sequestration !== undefined && (
                                <span>
                                  <strong>Carbon:</strong>{" "}
                                  {(
                                    data.carbon_sequestration * 0.00367
                                  ).toFixed(2)}{" "}
                                  MtCO2e
                                </span>
                              )}
                              <br />
                              {data.area_sum !== undefined && (
                                <span>
                                  <strong>Crop Area:</strong>{" "}
                                  {data.area_sum.toFixed(5)}
                                </span>
                              )}
                              <br />
                              {data.ndvi !== undefined && (
                                <span>
                                  <strong>NDVI:</strong> {data.ndvi.toFixed(5)}
                                </span>
                              )}
                              <br />
                              {data.gpp !== undefined && (
                                <span>
                                  <strong>GPP:</strong> {data.gpp.toFixed(2)}
                                </span>
                              )}
                              <br />
                              {data.npp !== undefined && (
                                <span>
                                  <strong>NPP:</strong> {data.npp.toFixed(2)}
                                </span>
                              )}
                            </Typography>
                          </div>
                        </Grid>
                      )
                    )}
                </Grid>
                <div className="mt-32 mb-8">
                  <h2>Trees Carbon Data</h2>
                  <Grid container spacing={2}>
                    {share.trees_carbon_data !== undefined &&
                      Object.entries(share.trees_carbon_data).map(
                        ([key, value]) =>
                          typeof value === "object" && value !== null
                            ? Object.entries([value]).map((n) => (
                                <Grid item xs={6} sm={6} key={key}>
                                  <Typography
                                    variant="body1"
                                    component="h5"
                                    className="flex flex-col"
                                  >
                                    <span>
                                      {" "}
                                      <strong>Species:</strong> {n[1].species}{" "}
                                    </span>
                                    <span>
                                      <strong>Common Name:</strong>{" "}
                                      {n[1].common_name}{" "}
                                    </span>
                                    <span>
                                      <strong>Local Name:</strong>
                                      {n[1].local_name}{" "}
                                    </span>
                                    <span>
                                      {" "}
                                      <strong>Carbon:</strong>{" "}
                                      {(n[1].carbon * 0.00367).toFixed(2)}
                                      {" MtCO2e "}
                                    </span>
                                    <span>
                                      {" "}
                                      <strong>biomass:</strong>{" "}
                                      {n[1].biomass.toFixed(2)}{" "}
                                    </span>
                                    <span>
                                      {" "}
                                      <strong>Number of trees:</strong>{" "}
                                      {n[1].number_of_trees}{" "}
                                    </span>
                                    <span>
                                      {" "}
                                      <strong>Average Age:</strong>{" "}
                                      {n[1].average_age}{" "}
                                    </span>
                                    <span>
                                      {" "}
                                      <strong>Average Height:</strong>{" "}
                                      {n[1].average_height}{" "}
                                    </span>
                                    <span>
                                      {" "}
                                      <strong>Average DBH:</strong>{" "}
                                      {n[1].average_dbh}{" "}
                                    </span>
                                  </Typography>
                                </Grid>
                              ))
                            : value
                      )}
                  </Grid>
                </div>
              </div>

              {/* <div className="section">
                
              </div> */}

              <div className="section">
                <h2>Crop Baseline Data</h2>
                <div className="">{renderData(share.crop_baseline_data)}</div>
              </div>

              <div className="section">
                <h2>Farm Management Data</h2>
                <div className="">{renderData(share.farm_management_data)}</div>
              </div>

              <div className="section">
                <h2>Energy Usage Data</h2>
                <div className="">{renderData(share.energy_usage_data)}</div>
              </div>
            </div>
          </TableContainer>

          <div className="mapContainer">
            <h2>Project Location</h2>
            <div>
              <MapContainer
                center={centerCoord}
                zoom={18}
                scrollWheelZoom={true}
              >
                <FullscreenControl position="topright" />

                <TileLayer
                  attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
                  url={`https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=${googleMapsApiKey}`}
                />

                <Polygon
                  pathOptions={{
                    color: "#FFFF00",
                    opacity: 0.8,
                    fillOpacity: 0.1,
                  }}
                  positions={reversedCoordinates}
                >
                  {/* <Tooltip sticky>{data.properties.name}</Tooltip> */}
                </Polygon>
              </MapContainer>
            </div>
          </div>
        </>
      )}
    </Container>
  );
}
