import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import QuizIcon from "@mui/icons-material/Quiz";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import GroupsIcon from "@mui/icons-material/Groups";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useTheme } from "@mui/material/styles";

export const DrawerList = ({user}) => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const firstSegment = pathSegments[0];

  const theme = useTheme();

  return (
    <div>
      <Typography variant="p" component="p" className="font-bold px-8 pt-4">
        MRV
      </Typography>

      <List>
        <ListItemButton
          selected={firstSegment === undefined ? true : false}
          sx={{
            backgroundColor: `${
              firstSegment === undefined
                ? theme.palette.primary.main
                : "inherit"
            } !important`,
          }}
          component={Link}
          to="/"
        >
          <ListItemIcon>
            <DashboardIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="p" component="p" className="font-medium">
                Summary
              </Typography>
            }
          />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/projects"
          selected={
            firstSegment === "projects"
              ? true
              : firstSegment === "report"
              ? true
              : false
          }
          sx={{
            backgroundColor: `${
              firstSegment === "projects"
                ? theme.palette.primary.main
                : firstSegment === "report"
                ? theme.palette.primary.main
                : "inherit"
            } !important`,
          }}
        >
          <ListItemIcon>
            <FolderCopyIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="p" component="p" className="font-medium">
                My Projects
              </Typography>
            }
          />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/reports"
          selected={firstSegment === "reports" ? true : false}
          sx={{
            backgroundColor: `${
              firstSegment === "reports"
                ? theme.palette.primary.main
                : "inherit"
            } !important`,
          }}
        >
          <ListItemIcon>
            <BarChartIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="p" className="font-medium">
                Reports
              </Typography>
            }
          />
        </ListItemButton>

        <>
          <ListItemButton
            component={Link}
            to={user.is_superuser === true ? `new-project` : `new-project`}
            // to={user.is_superuser === true ? `new-project/business` : `new-project`}
            selected={firstSegment === "new-project" ? true : false}
            sx={{
              backgroundColor: `${
                firstSegment === "new-project"
                  ? theme.palette.primary.main
                  : "inherit"
              } !important`,
            }}
          >
            <ListItemIcon>
              <CreateNewFolderIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="p" className="font-medium">
                  New Project
                </Typography>
              }
            />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/team-members"
            selected={firstSegment === "team-members" ? true : false}
            sx={{
              backgroundColor: `${
                firstSegment === "team-members"
                  ? theme.palette.primary.main
                  : "inherit"
              } !important`,
            }}
          >
            <ListItemIcon>
              <GroupsIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="p" className="font-medium">
                  Team Members
                </Typography>
              }
            />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/faqs"
            selected={firstSegment === "faqs" ? true : false}
            sx={{
              backgroundColor: `${
                firstSegment === "faqs" ? theme.palette.primary.main : "inherit"
              } !important`,
            }}
          >
            <ListItemIcon>
              <QuizIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="p" className="font-medium">
                  FAQs
                </Typography>
              }
            />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/sign-out"
            selected={firstSegment === "sign-out" ? true : false}
            sx={{
              backgroundColor: `${
                firstSegment === "sign-out"
                  ? theme.palette.primary.main
                  : "inherit"
              } !important`,
            }}
          >
            <ListItemIcon>
              <LogoutIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="p" className="font-medium">
                  Logout
                </Typography>
              }
            />
          </ListItemButton>
        </>
      </List>
    </div>
  );
};
