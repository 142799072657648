import { motion } from "framer-motion";
import { Container, Typography } from "@mui/material";
import VerifyHeaderWidget from "./widgets/VerifyHeaderWidget";
import VerificationStatusWidget from "./widgets/VerificationStatusWidget";
import { LeafLayers } from "../reports/maps/LeafLayers";
import VerifySummaryWidget from "./widgets/VerifySummaryWidget";
import VerificationCompareWidget from "./widgets/VerificationCompareWidget";
import VerificationReportWidget from "./widgets/VerificationReportWidget";

function MRVVerifyDetails() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <Container component="main" maxWidth="xl">
      <div className="mb-64">
        <motion.div
          className="grid grid-cols-1 gap-24 w-full min-w-0 pt-32 pb-0 px-24"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <motion.div variants={item}>
            <VerifyHeaderWidget />
          </motion.div>
        </motion.div>

        <motion.div
          className="grid grid-cols-1 gap-12 w-full min-w-0 pb-4 px-24"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-12 w-full mt-12">
            <div className="grid gap-12 sm:grid-flow-col xl:grid-flow-row">
              <motion.div variants={item} className="flex flex-col flex-auto">
                <VerifySummaryWidget />
              </motion.div>

              <motion.div variants={item} className="flex flex-col flex-auto">
                <VerificationStatusWidget />
              </motion.div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className="grid sm:grid-cols-1 gap-4 w-full px-24 py-8"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <LeafLayers />
        </motion.div>

        <div className="flex flex-col w-full my-8 sm:col-span-3 px-24 pt-16">
          <Typography
            variant="p"
            className="text-2xl font-semibold tracking-tight leading-6"
          >
            Verification Results Comparison
          </Typography>
        </div>

        <motion.div
          className="grid grid-cols-1 gap-12 w-full min-w-0 pb-4 px-24"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-12 w-full mt-0">
            <div className="grid gap-12 sm:grid-flow-col xl:grid-flow-row">
              <motion.div variants={item} className="flex flex-col flex-auto">
                <VerificationCompareWidget status={1} />
              </motion.div>

              <motion.div variants={item} className="flex flex-col flex-auto">
                <VerificationCompareWidget status={2} />
              </motion.div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className="grid grid-cols-1 gap-24 w-full min-w-0 pt-2 pb-0 px-24"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <motion.div variants={item}>
            <VerificationReportWidget />
          </motion.div>
        </motion.div>
      </div>
    </Container>
  );
}

export default MRVVerifyDetails;
