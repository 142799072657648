import React, { memo } from "react";
import { useTheme } from "@mui/material/styles";
import { Chip, Paper, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

function VerificationStatusIndicator() {
  const theme = useTheme();

  const options = {
    chart: {
      height: 240,
      type: "radialBar",
    },
    series: [67], // This represents the verification progress percentage
    colors: [theme.palette.primary.main], // Use primary color from theme
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
          background: theme.palette.grey[900], // Use a grey shade from theme for dark background
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15, // Shadow effect for the track
          },
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: theme.palette.background.paper, // Use text primary color from theme
            fontSize: "13px", // Styling for the label
          },
          value: {
            color: theme.palette.background.paper, // Use text primary color from theme
            fontSize: "30px",
            show: true, // Styling for the progress percentage
          },
        },
      },
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "dark",
    //     type: "vertical",
    //     gradientToColors: [theme.palette.secondary.dark], // Use secondary color from theme for gradient
    //     stops: [0, 100],
    //   },
    // },
    stroke: {
      lineCap: "round", // Rounded edges for the progress bar
    },
    labels: ["Progress"], // Label for the chart
  };

  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden p-24">
      <div className="flex flex-col sm:flex-row items-start justify-between">
        <Typography
          variant="p"
          className="text-lg font-medium tracking-tight leading-6 truncate"
        >
          Progress
        </Typography>
        
      </div>

      <div className="flex flex-col flex-auto mt-0 h-112">
        <ReactApexChart
          options={options}
          series={options.series}
          type="radialBar"
          height={options.chart.height}
        />
      </div>
    </Paper>
  );
}

export default memo(VerificationStatusIndicator);
