import { MapContainer, Polygon, TileLayer, Tooltip } from "react-leaflet";
import "../styles/home-leaf.css";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import { backend_url, googleMapsApiKey } from "../services/Constants";
import { useEffect, useState } from "react";
import {
  Tooltip as MaterialTooltip,
  List,
  ListItem,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

export const HomeProjects = ({
  token,
  selectedPolygons,
  setSelectedPolygons,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [projects, setProjects] = useState();
  const totalItems = projects !== undefined && projects.count;

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/project/?` +
          new URLSearchParams({
            mrv_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setProjects(data));
    }

    fetchData();
  }, [token, currentPage]);

  const farm_geometries =
    projects !== undefined &&
    projects.results.features.map((n) => ({
      project_id: n.id,
      geometry: n.geometry,
      properties: n.properties,
    }));

  return (
    farm_geometries && (
      <HomeMap
        selectedPolygons={selectedPolygons}
        setSelectedPolygons={setSelectedPolygons}
        polygons={farm_geometries}
      />
    )
  );
};

const HomeMap = ({ polygons, selectedPolygons, setSelectedPolygons }) => {
  const parseGeometry = (geometryString) => {
    const polygonString = geometryString.split(";")[1].trim().slice(9);
    const coordinates = polygonString
      .split(",")
      .map((coord) => {
        const [lng, lat] = coord.trim().split(" ").map(parseFloat);
        if (!isNaN(lng) && !isNaN(lat)) {
          return [lat, lng];
        } else {
          return null;
        }
      })
      .filter((coord) => coord !== null);
    return coordinates;
  };

  const handlePolygonClick = (polygon) => {
    setSelectedPolygons((prevSelected) => {
      const alreadySelected = prevSelected.find(
        (item) => item.project_id === polygon.project_id
      );

      if (alreadySelected) {
        return prevSelected.filter(
          (item) => item.project_id !== polygon.project_id
        );
      } else {
        return [...prevSelected, polygon];
      }
    });
  };

  const handleClearSelection = () => {
    setSelectedPolygons([]);
  };

  const parsedPolygons = polygons.map(
    ({ geometry, properties, project_id }) => ({
      coordinates: parseGeometry(geometry),
      properties,
      project_id,
    })
  );

  const firstPolygon =
    parsedPolygons[0] !== undefined ? parsedPolygons[0].coordinates : [[3, 36]];
  const centerCoord = firstPolygon[Math.floor(firstPolygon.length / 2)];

  return (
    <div style={{ display: "flex" }}>
      <MapContainer
        style={{ flex: 1 }}
        center={centerCoord}
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
          url={`https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=${googleMapsApiKey}`}
        />

        <FullscreenControl position="topright" />

        {parsedPolygons.map((polygon, index) => (
          <Polygon
            key={index}
            pathOptions={{ color: "#FFFF00", opacity: 0.8, fillOpacity: 0.1 }}
            positions={polygon.coordinates}
            eventHandlers={{
              click: () => handlePolygonClick(polygon),
            }}
          >
            <Tooltip>
              <MaterialTooltip
                title={
                  <>
                    <div>Name: {polygon.properties.name}</div>
                    <div>
                      Created At:{" "}
                      {new Date(polygon.properties.created_at).toLocaleString()}
                    </div>
                    <div>Description: {polygon.properties.description}</div>
                    <div>Status: {polygon.properties.status}</div>
                  </>
                }
                arrow
              >
                <span>{polygon.properties.name}</span>
              </MaterialTooltip>
            </Tooltip>
          </Polygon>
        ))}
      </MapContainer>

      {selectedPolygons.length > 0 && (
        <Box
          style={{
            width: "300px",
            height: "75vh",
            marginTop: "84px",
            overflowY: "auto",
            padding: "16px",
            backgroundColor: "#f5f5f5",
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 1000,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" gutterBottom>
              Selected Projects
            </Typography>
            <IconButton onClick={handleClearSelection}>
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            {selectedPolygons.map((polygon, index) => (
              <ListItem key={index}>
                <Card style={{ width: "100%" }} component={Link} to={`/report/${polygon.project_id}`}>
                  <CardContent>
                    <Typography variant="h6">
                      {polygon.properties.name}
                    </Typography>
                    <Typography variant="body2">
                      Created At:{" "}
                      {new Date(polygon.properties.created_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                      Description: {polygon.properties.description}
                    </Typography>
                    <Typography variant="body2">
                      Status: {polygon.properties.status}
                    </Typography>
                  </CardContent>
                </Card>
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClearSelection}
            style={{ marginTop: "16px" }}
          >
            Clear Selection
          </Button>
        </Box>
      )}
    </div>
  );
};
