import { createTheme } from "@mui/material/styles";

export const lightPaletteText = {
  primary: "rgb(17, 24, 39)",
  secondary: "rgb(107, 114, 128)",
  disabled: "rgb(149, 156, 169)",
};

export const darkPaletteText = {
  primary: "rgb(255,255,255)",
  secondary: "rgb(148, 163, 184)",
  disabled: "rgb(156, 163, 175)",
};

const theme = createTheme({
  palette: {
    mode: "light",
    divider: "#e2e8f0",
    text: lightPaletteText,
    primary: {
      light: "#BBE2DA",
      main: "#05B050",
      dark: "#29333F",
      contrastText: darkPaletteText.primary,
    },
    secondary: {
      light: "#FFD0C1",
      main: "#FB8A2E",
      dark: "#FF3413",
      contrastText: darkPaletteText.primary,
    },
    info: {
      main: "#29333F",
    },
    warning: {
      main: "#0065ff",
    },
    background: {
      paper: "#FFFFFF",
      default: "#F1F1F1",
    },
    error: {
      light: "#ffcdd2",
      main: "#f44336",
      dark: "#b71c1c",
    },
  },
  status: {
    danger: "#C32021",
    success: "#A3CF73",
  },

  // initial theme
  // palette: {
  //   mode: "light",
  //   divider: "#e2e8f0",
  //   text: lightPaletteText,
  //   primary: {
  //     light: "#BBE2DA",
  //     main: "#1B9E85",
  //     dark: "#087055",
  //     contrastText: darkPaletteText.primary,
  //   },
  //   secondary: {
  //     light: "#FFD0C1",
  //     main: "#FF6231",
  //     dark: "#FF3413",
  //     contrastText: darkPaletteText.primary,
  //   },
  //   background: {
  //     paper: "#FFFFFF",
  //     default: "#F2F8F1",
  //   },
  //   error: {
  //     light: "#ffcdd2",
  //     main: "#f44336",
  //     dark: "#b71c1c",
  //   },
  // },
  // status: {
  //   danger: "orange",
  // },

  // Dark Theme

  // palette: {
  //     mode: 'dark',
  //     divider: 'rgba(241,245,249,.12)',
  //     text: darkPaletteText,
  //     primary: {
  //       light: 'FFECC5',
  //       main: '#FEBE3E',
  //       dark: '#FD991B',
  //       contrastText: lightPaletteText.primary,
  //     },
  //     secondary: {
  //       light: '#FFC8C7',
  //       main: '#FE4644',
  //       dark: '#FD201F',
  //       contrastText: lightPaletteText.primary,
  //     },
  //     background: {
  //       paper: '#2A2E32',
  //       default: '#212529',
  //     },
  //     error: {
  //       light: '#ffcdd2',
  //       main: '#f44336',
  //       dark: '#b71c1c',
  //     },
  //   },
  //   status: {
  //     danger: 'orange',
  //   },

  typography: {
    fontFamily: [
      "Poppins",
      "Maven+Pro",
      "sans-serif",
    ].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiDateTimePicker: {
      defaultProps: {
        PopperProps: { className: "z-9999" },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        PopperProps: { className: "z-9999" },
      },
    },
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "text",
        color: "inherit",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          // lineHeight: 1,
        },
        // sizeMedium: {
        //   borderRadius: 20,
        //   height: 40,
        //   minHeight: 40,
        //   maxHeight: 40,
        // },
        // sizeSmall: {
        //   borderRadius: "15px",
        // },
        // sizeLarge: {
        //   borderRadius: "28px",
        // },
        contained: {
          boxShadow: "none",
          "&:hover, &:focus": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        contained: {
          borderRadius: 18,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        // rounded: {
        //   borderRadius: 16,
        // },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiSelect: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 40,
          lineHeight: 1,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          "&:before, &:after": {
            display: "none",
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiRadio: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { color: "text.secondary" },
          style: {
            color: "text.secondary",
          },
        },
      ],
    },
  },
});

export default theme;
