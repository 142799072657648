import { Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const CarbonBarChart = ({ cropCarbon, treeCarbon }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Carbon",
        data: [0, 40, 25], // Dummy data: [Soil Carbon, Tree Carbon, Crop Carbon]
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Soil Carbon", "Tree Carbon", "Crop Carbon"],
        // categories: ["Soil Carbon", "Tree Carbon", "Crop Carbon"],
        labels: {
          style: {
            colors: ["#29333F", "#05B050", "#FB8A2E"],
            fontSize: "12px",
          },
        },
      },
      colors: ["#29333F", "#05B050", "#FB8A2E"],
      title: {
        text: "Carbon Content",
        align: "center",
        style: {
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
      yaxis: {
        title: {
          text: "Amount (tCO2e)",
        },
      },
      legend: {
        show: false,
      },
    },
  });

  const calculateCropCarbon = () => {
    let totalCarbon = 0;

    if (cropCarbon !== 0) {
      cropCarbon[0].carbon.forEach((crop_1) => {
        const crop_0 = cropCarbon[0].carbon.find(
          (crop) => crop.crop_name === crop_1.crop_name
        );
        if (crop_0) {
          // const carbonDifference = crop_1.carbon_sequestration - crop_0.carbon_sequestration;
          totalCarbon += crop_1.carbon_sequestration;
        }
      });
    }

    // Convert total carbon in kg to metric tons of CO2e
    const totalCarbonInMetricTonsCO2e = totalCarbon * 0.00367;

    return totalCarbonInMetricTonsCO2e.toFixed(2);
  };

  const treeCarbonSum = () => {
    let totalCarbon = 0;
    if (treeCarbon !== 0) {
      treeCarbon.forEach((tree) => {
        totalCarbon += tree.carbon;
      });
    }

    // Convert total carbon in kg to metric tons of CO2e
    const totalCarbonInMetricTonsCO2e = totalCarbon * 0.00367;
    return totalCarbonInMetricTonsCO2e.toFixed(2);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = {
        data: [0.0, treeCarbonSum(), calculateCropCarbon()], 
      };
      setChartData((prevState) => ({
        ...prevState,
        series: [{ ...prevState.series[0], data: response.data }],
      }));
    };

    fetchData();
  }, [calculateCropCarbon(), treeCarbonSum()]);

  return (
    <Paper className="flex flex-col flex-auto max-h-256 p-24 mt-2 shadow rounded-2xl overflow-hidden  my-4 mr-2">
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default CarbonBarChart;
