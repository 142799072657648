import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Card, CardContent } from "@mui/material";

const CropCarbonBarChart = ({ cropCarbon, selectedChip }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: ["#FB8A2E", "#FB8A2E"],
            fontSize: "12px",
          },
        },
      },
      colors: ["#FB8A2E", "#FB8A2E"],
      title: {
        text: "Crop Carbon Sequestration",
        align: "center",
        style: {
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
      yaxis: {
        title: {
          text: "Carbon Content (MtCO2e)",
        },
      },
      legend: {
        show: false,
      },
    },
  });


  useEffect(() => {
    const processData = () => {
      const cropCarbonData = cropCarbon[0].carbon
        .map((crop_1) => {
          const crop_0 = cropCarbon[0].carbon.find(
            (crop) => crop.crop_name === crop_1.crop_name
          );
          if (crop_0) {
            return {
              crop_name: crop_1.crop_name,
              carbon_value: crop_1.carbon_sequestration * 0.00367,
            };
          }
          return null;
        })
        .filter((crop) => crop !== null);

      setChartData((prevState) => ({
        ...prevState,
        series: [
          {
            name: "Carbon",
            data: cropCarbonData.map((crop) => crop.carbon_value.toFixed(2)),
          },
        ],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: cropCarbonData.map((crop) => crop.crop_name),
          },
        },
      }));
    };

    processData();
  }, [selectedChip]);

  return (
    <Card className="flex flex-col flex-auto max-h-256 p-24 mt-2 shadow rounded-2xl overflow-hidden my-4 ml-2">
      <CardContent>
        {/* <Typography variant="h5" component="div" gutterBottom>
          Crop Carbon Sequestration
        </Typography> */}
        <div className="chart">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default CropCarbonBarChart;
