import { Button, Typography } from "@mui/material";
import { memo } from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import { Link } from "react-router-dom";

function VerifyHeaderWidget() {
  return (
    <div className="flex w-full container">
      <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-24 md:p-32 pb-0 md:pb-0">
        <div className="flex flex-col flex-auto">
          <Typography
            variant="p"
            className="text-3xl font-semibold tracking-tight leading-8"
          >
            Verify - Zowasel Farm
          </Typography>
        </div>
        <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
          <Button
            className="whitespace-nowrap"
            startIcon={<SettingsIcon size={20} />}
          >
            Project Settings
          </Button>
          <Link to="/">
            <Button
              className="whitespace-nowrap"
              variant="contained"
              color="primary"
              startIcon={<DomainVerificationIcon size={20} />}
            >
              View Project
            </Button>
          </Link>
          <Button
            className="whitespace-nowrap"
            variant="contained"
            color="secondary"
            startIcon={<SaveAltIcon size={20} />}
          >
            Export Project
          </Button>
        </div>
      </div>
    </div>
  );
}

export default memo(VerifyHeaderWidget);
