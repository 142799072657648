import { motion } from "framer-motion";
import { Container, Typography } from "@mui/material";
import TotalArea from "./widgets/TotalArea";

function MRVReports() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <Container component="main" maxWidth="xl">
      <div>
        <Typography
          variant="p"
          component="h5"
          className="text-md md:text-lg text-center font-semibold  "
        >
          Reports
        </Typography>

        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <motion.div variants={item}>
            <TotalArea />
          </motion.div>
          <motion.div variants={item}>
            <TotalArea />
          </motion.div>
        </motion.div>
      </div>
    </Container>
  );
}

export default MRVReports;
