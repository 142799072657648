import React from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
import logo1 from "../assets/group_4036.png";
import { Link } from "react-router-dom";
import TelegramIcon from "@mui/icons-material/Telegram";

const Footer = () => {
  return (
    <Box
      sx={{
        flexShrink: 0,
        backgroundColor: "primary.dark",
        color: "background.paper",
        p: 3,
        mt: 4,
      }}
    >
      <Container component="main" maxWidth="lg">
        <Grid container spacing={3}>
          {/* <Grid item xs={12} className="py-24">
            <TextField
              label="Enter your email address"
              variant="outlined"
            />
            <IconButton>
              <TelegramIcon fontSize="large" color="primary" />
            </IconButton>
          </Grid> */}

          {/* <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    mr: 1,
                    mt: -1,
                    mb: 2,
                  }}
                >
                  <div className="flex flex-1 mx-1">
                    <Link to="/" underline="none">
                      <img src={logo1} className="font-bold h-24" alt="logo" />
                    </Link>
                  </div>
                </Box>
                <Typography>Collins Street West, Victoria</Typography>
                <Typography className="pb-4">Australia (AU4578).</Typography>
                <Typography>Email:</Typography>
                <Typography className="pb-4">info@Zowasel.com</Typography>
                <Typography>Call:</Typography>
                <Typography className="pb-4">91 855 742 62548</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="h6" className="pb-4">
                  About
                </Typography>
                <Typography className="pb-4">Company</Typography>
                <Typography className="pb-4">Contact</Typography>
                <Typography className="pb-4">Help</Typography>
                <Typography className="pb-4">Blog</Typography>
                <Typography className="pb-4">Pricing</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography className="pb-4" variant="h6">
                  Features
                </Typography>
                <Typography className="pb-4">Marketplace</Typography>
                <Typography className="pb-4">Crop Centers</Typography>
                <Typography className="pb-4">Trust and Security</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography className="pb-4" variant="h6">
                  Explore
                </Typography>
                <Typography className="pb-4">How it Works</Typography>
                <Typography className="pb-4">Terms and Conditions</Typography>
                <Typography className="pb-4">Privacy</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography className="pb-4" variant="h6">
                  Download Apps
                </Typography>
                <Typography className="pb-4">Google Play</Typography>
                <Typography className="pb-4">App Store</Typography>
              </Grid>
            </Grid>
          </Grid> */}

          {/* Third Row: Copyright and Social Media Icons */}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  className="pb-4"
                  variant="body2"
                  color="textSecondary"
                >
                  &copy; 2024 Zowasel Inc. All Right Reserved.
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <IconButton href="https://facebook.com" color="primary">
                      <Facebook />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton href="https://twitter.com" color="primary">
                      <Twitter />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton href="https://instagram.com" color="primary">
                      <Instagram />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton href="https://linkedin.com" color="primary">
                      <LinkedIn />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
