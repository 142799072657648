import React, { useState } from "react";
import {
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { backend_url } from "../../services/Constants";
import axios from "axios";
import MRVSnackbar from "../../components/MRVSnackbar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Loading from "../../components/Loading";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

export const AddProjectMembers = (props) => {
  const { token, project_id, sampleTakers, sampleFarmers } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const schema = yup.object().shape({
    role: yup
      .string()
      .required()
      .oneOf(["Buyers", "Sample", "Farmer"], "Please select the role"),
    user_id: yup.string().notRequired("This field is required"),
    shared_id: yup
      .string()
      .required("You must enter the shared id from zowasel.com"),
  });

  const defaultValues = {
    role: "",
    user_id: "",
    shared_id: "",
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function submit({ role, shared_id }) {
    const farmMembers = {
      project_id: project_id,
      project: project_id,
      _id: shared_id,
      role: "Sample",
      user: 1,
      // user: parseInt(user_id),
    };

    axios
      .put(
        `${backend_url}/farm/members/`,
        farmMembers,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Successfully added project member.");
        setLoading(false);
        handleClose();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.response.data.details);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      {sampleFarmers || sampleTakers ? (
        <Button
          className="whitespace-nowrap"
          variant="contained"
          color="secondary"
          startIcon={<AddIcon size={20} />}
          onClick={() =>
            navigate(
              sampleTakers
                ? `/report/members?id=${project_id}&role=Taker`
                : `/report/members?id=${project_id}`
            )
          }
        >
          {sampleTakers ? "Add Taker" : "Add Farmers"}
        </Button>
      ) : (
        <Button
          className="whitespace-nowrap"
          variant="contained"
          color="secondary"
          startIcon={<AddIcon size={20} />}
          onClick={handleClickOpen}
        >
          {sampleTakers ? "Add Taker" : "Add Members"}
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography
            component="p"
            variant="p"
            className="px-4 py-16 text-sm md:text-md font-semibold sm:font-bold"
          >
            {"Add Members"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Add project members</DialogContentText> */}
          <form
            name="tripForm"
            noValidate
            className="flex flex-col justify-center w-full mt-8 sm:mt-8  md:min-w-320"
            onSubmit={handleSubmit(submit)}
          >
            {openMessage === true && (
              <MRVSnackbar
                openMessage={openMessage}
                severity={severity}
                message={message}
              />
            )}

            <div className="sm:flex my-4">
              <Controller
                name="shared_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 2 }}
                    label="Shared ID From Zowasel"
                    error={!!errors.shared_id}
                    helperText={errors?.shared_id?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="sm:flex my-4">
              <Controller
                name="user_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ m: 1 }}
                    id="user_id"
                    label="Username"
                    type="text"
                    error={!!errors.user_id}
                    helperText={errors?.user_id?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            {/* <div className="sm:flex my-4">
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <FormControl sx={{ mb: 2 }} fullWidth error={!!errors.role}>
                    <InputLabel id="role">Role</InputLabel>
                    <Select labelId="role" id="role" label="Role" {...field}>
                      <MenuItem value={"Sample"}>Sample Taker</MenuItem>
                    </Select>
                    <FormHelperText>{errors?.role?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </div> */}

            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{ mx: 1 }}
                variant="contained"
                color="error"
              >
                Cancel
              </Button>
              <Button
                sx={{ mx: 1 }}
                variant="contained"
                color="success"
                onClick={() => {
                  setOpenMessage(false);
                  setLoading(true);
                }}
                type="submit"
              >
                {loading === true ? <Loading /> : "Add Member"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
