import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";

function TotalCarbonStock({ cropCarbon, treeCarbon }) {
  const calculateCropCarbon = () => {
    let totalCarbon = 0;

    cropCarbon[0].carbon.forEach((crop_1) => {
      const crop_0 = cropCarbon[0].carbon.find(
        (crop) => crop.crop_name === crop_1.crop_name
      );
      if (crop_0) {
        // const carbonDifference = crop_1.carbon_sequestration - crop_0.carbon_sequestration;
        totalCarbon += crop_1.carbon_sequestration;
      }
    });

    // Convert total carbon in kg to metric tons of CO2e
    const totalCarbonInMetricTonsCO2e = totalCarbon * 0.00367;

    return totalCarbonInMetricTonsCO2e.toFixed(2);
  };

  const treeCarbonSum = () => {
    let totalCarbon = 0;
    treeCarbon.forEach((tree) => {
      totalCarbon += tree.carbon;
    });

    // Convert total carbon in kg to metric tons of CO2e
    const totalCarbonInMetricTonsCO2e = totalCarbon * 0.00367;
    return totalCarbonInMetricTonsCO2e.toFixed(2);
  };

  return (
    <Paper
      elevation={0}
      className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden mb-8 py-4"
    >
      <div className="text-center  px-8 ">
        <Typography
          variant="p"
          className="px-8 text-md  font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          Total Carbon
        </Typography>
      </div>
      <div className="text-center mt-8 flex flex-col">
        <Typography
          variant="p"
          className="text-xl sm:text-3xl font-bold tracking-tight leading-none text-green-500"
        >
          {cropCarbon !== 0 &&
            treeCarbon !== 0 &&
            parseInt(treeCarbonSum()) + parseInt(calculateCropCarbon())}

          {cropCarbon === 0 && treeCarbon !== 0 && treeCarbonSum()}
          {cropCarbon !== 0 && treeCarbon === 0 && calculateCropCarbon()}
        </Typography>
        <Typography variant="p" className="text-md font-medium py-3 text-green-600">
          MtCO2e
        </Typography>
      </div>
      {/* <Typography
        variant="p"
        className="flex items-baseline justify-center w-full pt-8 pb-24"
        color="text.secondary"
      >
        <br />
      </Typography> */}
    </Paper>
  );
}

export default memo(TotalCarbonStock);
