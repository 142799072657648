import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { backend_url, share_url } from "../../services/Constants";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ProjectDetailHeader from "./ProjectDetailHeader";
import AddProjectDataSets from "../measure/AddProjectDataSets";
import ProjectMembers from "../members/ProjectMembers";
import Loading from "../../components/Loading";
import ProjectDashboard from "./ProjectDashboard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MRVSnackbar from "../../components/MRVSnackbar";
import { AddSeason } from "../measure/projectInfo/AddSeason";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MRVReports(props) {
  const { token, user } = props;
  const { id } = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const reportRef = useRef();

  const data_url = query.get("data") === "true";
  const [openMessage, setOpenMessage] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(undefined);
  const [classifiedImage, setClassifiedImage] = useState(undefined);
  const [training, setTraining] = useState(undefined);
  const [showDescription, setShowDescription] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    query.get("sample_takers") === "true"
      ? "sample_takers"
      : query.get("project_report") === "true"
      ? "project_report"
      : query.get("project_data") === "true"
      ? "project_data"
      : "map"
  );
  const [farmSeason, setFarmSeason] = useState(undefined);
  const initialSeason = query.get("season") || "all";
  const [selectedChip, setSelectedChip] = useState(initialSeason);

  useEffect(() => {
    const currentSeason = query.get("season") || "all";
    setSelectedChip(currentSeason);
  }, [query]);

  const handleChipClick = (index) => {
    const newSelectedChip = index === selectedChip ? "all" : index;
    setSelectedChip(newSelectedChip);
    setOpenMessage(false);

    // Construct the new URL with the query parameter
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("season", newSelectedChip);

    // Reload the page with the new URL
    window.location.href = `?${searchParams.toString()}`;
  };

  useEffect(() => {
    const url = new URL(window.location);
    url.searchParams.delete("sample_takers");
    url.searchParams.delete("project_report");
    url.searchParams.delete("project_data");
    url.searchParams.set(currentTab, "true");
    navigate(`${url.pathname}?${url.searchParams.toString()}`, {
      replace: true,
    });
  }, [currentTab, navigate]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/project_details/?` +
          new URLSearchParams({
            project_id: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setProject(data));
    }

    fetchData();
  }, [token]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/season/?` +
          new URLSearchParams({
            project_id: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setFarmSeason(data));
    }

    fetchData();
  }, [token, id]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/training_data/?` +
          new URLSearchParams({
            project_id: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setTraining(data));
    }

    fetchData();
  }, [token]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/classified_image/?` +
          new URLSearchParams({
            // project_id: `${id}`,
            season_id: selectedChip,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setClassifiedImage(data));
    }

    fetchData();
  }, [token]);

  const project_data = project && project.results ? project.results : [];
  const classified_image_data =
    classifiedImage && classifiedImage.results ? classifiedImage.results : [];
  const year = classified_image_data
    ? classified_image_data.map((n) => n.year)
    : null;
  const wmsLink = classified_image_data
    ? classified_image_data.map((n) => n.image_url)
    : null;

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const downloadReport = async () => {
    const reportElement = reportRef.current;
    const canvas = await html2canvas(reportElement, { scale: 2 }); // Increase scale for higher resolution
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 295; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save("report.pdf");
  };

  return (
    project !== undefined &&
    project_data.features !== undefined &&
    project_data.features.length > 0 && (
      <>
        <Box
          sx={{
            flexShrink: 0,
            backgroundColor:
              currentTab === "map" ? "transparent" : "background.paper",
          }}
        >
          <motion.div
            className="w-full min-w-0 pt-32 "
            variants={container}
            initial="hidden"
            animate="show"
          >
            <motion.div variants={item}>
              <ProjectDetailHeader
                showDescription={showDescription}
                setShowDescription={setShowDescription}
                year={year}
                data={project_data.features[0]}
                token={token}
                id={id}
                training={training}
              />
            </motion.div>
          </motion.div>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              onClick={() => setCurrentTab("map")}
              sx={{
                backgroundColor:
                  currentTab === "map" ? "primary.main" : "white",
                color: currentTab === "map" ? "white" : "primary.main",
                "&:hover": {
                  backgroundColor: "inherit",
                },
              }}
            >
              Dashboard
            </Button>
            <Button
              onClick={() => setCurrentTab("project_data")}
              sx={{
                backgroundColor:
                  currentTab === "project_data" ? "primary.main" : "white",
                color: currentTab === "project_data" ? "white" : "primary.main",
                "&:hover": {
                  backgroundColor: "inherit",
                },
              }}
            >
              Project Data
            </Button>
            <Button
              onClick={() => setCurrentTab("sample_takers")}
              sx={{
                backgroundColor:
                  currentTab === "sample_takers" ? "primary.main" : "white",
                color:
                  currentTab === "sample_takers" ? "white" : "primary.main",
                "&:hover": {
                  backgroundColor: "inherit",
                },
              }}
            >
              Sample Takers
            </Button>
            <Button
              onClick={() => setCurrentTab("farmers")}
              sx={{
                backgroundColor:
                  currentTab === "farmers" ? "primary.main" : "white",
                color: currentTab === "farmers" ? "white" : "primary.main",
                "&:hover": {
                  backgroundColor: "inherit",
                },
              }}
            >
              Farmers
            </Button>
          </ButtonGroup>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className="flex flex-col items-left justify-between w-full min-w-0 pt-8 p-6">
              <Typography
                className="pb-4 px-8 font-bold"
                component="p"
                variant="p"
              >
                Filter by season
              </Typography>
              <Stack direction="row" spacing={1} className="mb-4">
                <Chip
                  key={"all"}
                  label={"All Seasons"}
                  onClick={() => {
                    handleChipClick("all");
                    setLoading(true);
                  }}
                  variant={selectedChip === "all" ? "contained" : "outlined"}
                  color={selectedChip === "all" ? "primary" : "default"}
                />
                {farmSeason !== undefined &&
                  farmSeason.count > 0 &&
                  farmSeason.results.map((farm) => (
                    <Chip
                      key={farm.season_id}
                      label={`${farm.year_from} - ${farm.year_to}`}
                      onClick={() => {
                        handleChipClick(farm.season_id);
                        setLoading(true);
                      }}
                      variant={
                        selectedChip === farm.season_id
                          ? "contained"
                          : "outlined"
                      }
                      color={
                        selectedChip === farm.season_id ? "primary" : "default"
                      }
                    />
                  ))}
              </Stack>
            </div>
          </Grid>

          <Grid item xs={4}>
            <div className="flex flex-row items-center justify-end w-full min-w-0 pt-8 p-6 mt-12">
              <AddSeason token={token} proj_id={id} />
              <div className="px-1" />
              {selectedChip !== "all" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenShare(true)}
                  className="mx-2"
                >
                  Share Report
                </Button>
              )}
            </div>
          </Grid>
        </Grid>

        {selectedChip !== "all" && (
          <ShareReport
            season_id={selectedChip}
            openShare={openShare}
            setOpenShare={setOpenShare}
            openMessage={openMessage}
            setOpenMessage={setOpenMessage}
          />
        )}

        <div
          style={{
            marginTop: currentTab === "map" ? "-8px" : "8px",
            marginBottom: "64px",
          }}
          ref={reportRef}
          id="report-content"
        >
          {currentTab === "map" && classifiedImage && (
            <motion.div
              className="w-full px-0"
              variants={container}
              initial="hidden"
              animate="show"
            >
              {training ? (
                training.features !== undefined && (
                  <ProjectDashboard
                    token={token}
                    id={id}
                    project_title={project_data.features[0].properties.name}
                    project_description={
                      project_data.features[0].properties.description
                    }
                    project_data={project_data}
                    showDescription={showDescription}
                    setShowDescription={setShowDescription}
                    training={training}
                    wmsLink={wmsLink[0]}
                    data={project_data.features[0]}
                    classified_image_data={classified_image_data}
                    farmSeason={farmSeason}
                    selectedChip={selectedChip}
                    setLoading={setLoading}
                    loading={loading}
                    setOpenMessage={setOpenMessage}
                    openMessage={openMessage}
                  />
                )
              ) : (
                <Loading />
              )}
            </motion.div>
          )}
          {currentTab === "project_data" && (
            <div>
              <AddProjectDataSets
                data_url={data_url}
                proj_id={id}
                token={token}
                farmSeason={farmSeason}
                setFarmSeason={setFarmSeason}
                selectedChip={selectedChip}
              />
            </div>
          )}
          {currentTab === "sample_takers" && (
            <div>
              <ProjectMembers
                project_title={project_data.features[0].properties.name}
                id={id}
                token={token}
                sampleTakers={true}
                user={user}
              />
            </div>
          )}
          {currentTab === "farmers" && (
            <div>
              <ProjectMembers
                project_title={project_data.features[0].properties.name}
                id={id}
                token={token}
                sampleFarmers={true}
                user={user}
              />
            </div>
          )}
        </div>
      </>
    )
  );
}

export default MRVReports;

function ShareReport(props) {
  const { setOpenShare, openShare, season_id, openMessage, setOpenMessage } =
    props;

  const shareUrl = `${share_url}/report/share/${season_id}`;

  const handleClose = () => {
    setOpenShare(false);
    setOpenMessage(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(shareUrl).then(
      () => {
        setOpenMessage(true);
        // alert("URL copied to clipboard");
      },
      (err) => {
        setOpenMessage(true);
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <Dialog onClose={handleClose} open={openShare}>
      {openMessage === true && (
        <MRVSnackbar
          openMessage={openMessage}
          severity={"success"}
          message={"URL copied to clipboard"}
        />
      )}

      <DialogTitle>Share this season's report</DialogTitle>
      <DialogContent>
        Open the link to download the report{" "}
        <IconButton
          color="primary"
          component={Link}
          to={shareUrl}
          target="_blank"
          edge="end"
        >
          <OpenInNewIcon />
        </IconButton>
      </DialogContent>
      <List sx={{ pt: 0 }}>
        <ListItem>
          <TextField
            fullWidth
            value={shareUrl}
            InputProps={{
              readOnly: true,
            }}
          />
          <IconButton color="primary" onClick={handleCopy} edge="end">
            <ContentCopyIcon />
          </IconButton>
        </ListItem>
        <ListItem>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Close
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}
