import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { backend_url } from "../../services/Constants";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import { IconButton, Paper } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ProjectMembers from "./ProjectMembers";
import Loading from "../../components/Loading";

function TeamMembersPage(props) {
  const { user, token } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [projects, setProjects] = useState();
  const totalItems = projects !== undefined && projects.count;
  const [searchValue, setSearchValue] = useState("");

  const project_data = projects && projects.results ? projects.results : [];
  const totalPages = Math.ceil(totalItems / 50);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/project/?` +
          new URLSearchParams({
            mrv_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setProjects(data));
    }

    fetchData();
  }, [token, currentPage]);

  return (
    user && (
      <div>
        <div className="w-full pt-4 sm:pt-4 lg:ltr:pr-0 lg:rtl:pl-0 mt-72">
          <motion.div
            className="px-12 py-8"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <Paper className="flex items-center  space-x-8 px-16 border-1 shadow-0 mb-12 py-4">
              <Input
                placeholder="Search members by name"
                className="flex flex-1"
                disableUnderline
                fullWidth
                value={searchValue}
                inputProps={{
                  "aria-label": "Search members by name",
                }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <IconButton>
                <SearchIcon color="primary" />
              </IconButton>
            </Paper>

            {/* <div className="pb-12 w-full">
              <Accordion className="px-8 py-6 rounded-full border-1 shadow-0">
                <AccordionSummary
                  expandIcon={<ExpandCircleDownIcon color="primary" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Filter Members By</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div> */}

            <motion.div variants={item} className="sm:col-span-2 md:col-span-4">
              <ProjectMembers
                teamPage={true}
                token={token}
                searchValue={searchValue}
                user={user}
              />
            </motion.div>

            {/* {projects && project_data.features !== undefined ? (
              project_data.features.length > 0 && (
                <motion.div
                  variants={item}
                  className="sm:col-span-2 md:col-span-4"
                >
                  <ProjectMembers
                    teamPage={true}
                    project_data={project_data}
                    token={token}
                  />
                </motion.div>
              )
            ) : (
              <Loading />
            )} */}
          </motion.div>
        </div>
      </div>
    )
  );
}

export default TeamMembersPage;
