import { Button, Typography, Paper } from "@mui/material";
import { memo } from "react";
import SettingsIcon from "@mui/icons-material/Settings";

function VerificationReportWidget() {
  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex w-full container">
        <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 py-24 md:px-32 pb-0 md:pb-0">
          <div className="flex flex-col flex-auto">
            <Typography
              variant="p"
              className="text-2xl font-semibold tracking-tight leading-6"
            >
              Verification Report Summary
            </Typography>
            <Typography
              variant="p"
              className="font-medium tracking-tight"
              color="text.secondary"
            >
              Key Findings
            </Typography>
          </div>
          <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
            <Button
              className="whitespace-nowrap"
              startIcon={<SettingsIcon size={20} />}
              variant="contained"
              color="primary"
            >
              Edit Report
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full container  min-w-0 py-24 md:px-32 pb-0 md:pb-24">
        <Typography variant="body1" gutterBottom>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
          facilisi. Cras eleifend arcu vel urna aliquet, eget lacinia magna
          vestibulum. Sed sit amet diam a risus tincidunt scelerisque. Duis
          venenatis ante nec nisl lacinia lobortis. Fusce eu metus ullamcorper,
          vehicula leo id, placerat libero. Nullam congue ligula ac libero
          consectetur fermentum. Nulla nec metus vel sapien varius volutpat.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Vivamus rhoncus metus eget tellus hendrerit, a efficitur lacus
          fermentum. Vestibulum efficitur sapien eget turpis lobortis, nec
          aliquam nunc varius. Phasellus pretium justo sed sem suscipit, ac
          mattis ligula posuere. Maecenas vel urna ut libero viverra rutrum vel
          at neque. Nullam auctor est ut sapien laoreet, et tincidunt eros
          viverra.
        </Typography>
        <Typography variant="body1">
          Integer at eros vitae lectus volutpat tincidunt. Morbi malesuada, eros
          a tincidunt dictum, ligula urna placerat velit, nec interdum dui augue
          vitae magna. Ut rhoncus lorem nec ligula vehicula, ac auctor felis
          convallis. Curabitur sodales felis in nisi iaculis, in varius lorem
          molestie. Phasellus convallis orci eget lectus maximus, vitae pharetra
          purus rhoncus.
        </Typography>
      </div>
    </Paper>
  );
}

export default memo(VerificationReportWidget);
