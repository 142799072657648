import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import MeasuredCarbonWidget from "./widgets/MeasuredCarbonWidget";
import AcreageWidget from "./widgets/AcreageWidget";
import TotalProjectsWidget from "./widgets/TotalProjectsWidget";
import OngoingVerificationWidget from "./widgets/OngoingVerificationWidget";
import { backend_url } from "../services/Constants";
import SampleTakersWidget from "./widgets/SampleTakersWidget";
import FarmersWidget from "./widgets/FarmersWidget";
import CropsWidget from "./widgets/CropsWidget";
import { HomeProjects } from "./HomeMap";
import CropCarbonWidget from "./widgets/CropCarbonWidget";
import TreeCarbonWidget from "./widgets/TreeCarbonWidget";

function HomePage(props) {
  const { user, token } = props;
  const [summary, setSummary] = useState(0);
  const [selectedPolygons, setSelectedPolygons] = useState([]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    async function fetchData() {
      const projectIds = selectedPolygons
        .map((polygon) => polygon.project_id)
        .join(",");
      const url = selectedPolygons.length
        ? `${backend_url}/farm/summary/?projects_id=${projectIds}`
        : `${backend_url}/farm/summary/`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setSummary(data));
    }

    fetchData();
  }, [token, selectedPolygons]);

  return (
    user &&
    summary !== undefined && (
      <div>
        {/* {user.username !== undefined && (
          <HomeHeader user={user} projects={project_data.features} />
        )} */}

        <div className="grid grid-cols-12 w-full min-w-0 pt-8 pt-32 sm:pt-48">
          <motion.div
            className="col-span-3 mt-12"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <motion.div variants={item}>
              <MeasuredCarbonWidget summary={summary} />
            </motion.div>
            <motion.div variants={item}>
              <CropCarbonWidget summary={summary} />
            </motion.div>
            <motion.div variants={item}>
              <TreeCarbonWidget summary={summary} />
            </motion.div>
            <motion.div summary={summary} variants={item}>
              <AcreageWidget summary={summary} />
            </motion.div>
          </motion.div>

          <motion.div
            className="col-span-9 "
            variants={container}
            initial="hidden"
            animate="show"
          >
            <motion.div className="pt-2" summary={summary} variants={item}>
              <HomeProjects
                selectedPolygons={selectedPolygons}
                setSelectedPolygons={setSelectedPolygons}
                token={token}
              />
            </motion.div>
          </motion.div>
        </div>

        <div className="w-full lg:ltr:pr-0 lg:rtl:pl-0">
          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-12 w-full min-w-0"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <motion.div variants={item}>
              <TotalProjectsWidget summary={summary} />
            </motion.div>
            <motion.div variants={item}>
              <OngoingVerificationWidget summary={summary} />
            </motion.div>
            {/* <motion.div variants={item}>
              <AgroforestryWidget summary={summary} />
            </motion.div> */}
            {/* <motion.div variants={item}>
              <CarbonSeqWidget summary={summary} />
            </motion.div> */}
            <motion.div variants={item}>
              <SampleTakersWidget summary={summary} />
            </motion.div>
            <motion.div summary={summary} variants={item}>
              <FarmersWidget summary={summary} />
            </motion.div>
          </motion.div>
          <CropsWidget summary={summary} />
        </div>
      </div>
    )
  );
}

export default HomePage;
