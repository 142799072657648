import {
  MapContainer,
  Polygon,
  WMSTileLayer,
  TileLayer,
  Marker,
  Popup,
} from "react-leaflet";
import "../../../styles/leaf.css";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import moment from "moment";
import Legend from "./Legend";
import { useState } from "react";
import { divIcon } from "leaflet";
import { googleMapsApiKey } from "../../../services/Constants";
import { defaultMarkup, setMarkup } from "./icons";

function parseWMSUrl(url) {
  const urlObj = new URL(url);
  const params = urlObj.searchParams;

  const layers = params.get("layers");
  const version = params.get("version");
  const format = "image/png";

  // Constructing the base WMS URL by removing the query string
  const baseUrl = `${urlObj.protocol}//${urlObj.host}${urlObj.pathname}`;
  return { baseUrl, layers, format, version };
}

export const LeafLayers = ({
  data,
  wmsLink,
  training,
  selectedCategory,
  showDescription,
  setShowDescription,
  project_data,
  project_title,
  token,
  id,
  project_description,
  classified_image_data,
}) => {
  const [raster, setRaster] = useState(true);
  const [activeClass, setActiveClass] = useState("all");
  const multiPolygon = data.geometry;

  const parseGeometry = (geometryString) => {
    // Remove the SRID part and extract the coordinates from the polygon string
    const polygonString = geometryString.split(";")[1].trim().slice(9); // Remove 'SRID=4326;'
    // Split the coordinates and convert them to LatLng pairs
    const coordinates = polygonString
      .split(",")
      .map((coord) => {
        const [lng, lat] = coord.trim().split(" ").map(parseFloat);
        if (!isNaN(lng) && !isNaN(lat)) {
          // Ensure both latitude and longitude are valid numbers
          return [lat, lng];
        } else {
          // console.error("Invalid coordinates:", coord.trim());
          return null; // Handle invalid coordinates gracefully
        }
      })
      .filter((coord) => coord !== null); // Remove any null values from the array
    return coordinates;
  };

  // Get the coordinates for the polygon
  const polygonCoordinates = parseGeometry(multiPolygon);
  const centerCoord =
    polygonCoordinates[Math.floor(polygonCoordinates.length / 2)];

  // Parsing the WMS service URL
  const { baseUrl, layers, format, version } =
    wmsLink !== undefined && parseWMSUrl(wmsLink);

  const iconProperties = {
    className: "",
    iconSize: [32, 44],
    iconAnchor: [16, 44],
    popupAnchor: [3.5, -11],
  };

  const createIconMarkups = (defaultValue) => {
    const markups = {};
    for (let i = 0; i <= 5; i++) {
      markups[i] = setMarkup;
    }
    markups.default = defaultValue;
    return markups;
  };

  const getCategoryIcon = (category, classType) => {
    if (category === -1 && classType !== "Notset") {
      return divIcon({
        ...iconProperties,
        html: setMarkup,
      });
    }

    if (category === -1 && classType === "Notset") {
      return divIcon({
        ...iconProperties,
        html: defaultMarkup,
      });
    }

    if (category !== -1 && classType !== "Notset") {
      return divIcon({
        ...iconProperties,
        html: setMarkup,
      });
    }
  };

  var agencyCoordinates = training.features.map((trainPoint) => ({
    latitude: trainPoint.geometry.coordinates[0],
    longitude: trainPoint.geometry.coordinates[1],
    class_type: trainPoint.properties.class_type,
    class_value: trainPoint.properties.class_value,
    training_date: trainPoint.properties.training_date,
    icon: getCategoryIcon(
      trainPoint.properties.class_value,
      trainPoint.properties.class_type
    ),
  }));

  const calculateCounts = (features) => {
    const counts = features.reduce((acc, feature) => {
      const classType = feature.properties.class_type;
      if (!acc[classType]) {
        acc[classType] = 0;
      }
      acc[classType]++;
      return acc;
    }, {});

    // Calculate total count
    counts["all"] = features.length;

    // Calculate 'not set' count by subtracting known categories from the total
    const knownCategoryCounts = Object.keys(counts)
      .filter((key) => key !== "all")
      .reduce((sum, key) => sum + counts[key], 0);

    counts["not_set"] = counts["all"] - knownCategoryCounts;

    return counts;
  };

  const counts = calculateCounts(training.features);

  const filteredAgencyCoordinates =
    activeClass === "all"
      ? agencyCoordinates
      : activeClass === "not_set"
      ? agencyCoordinates.filter((agency) => agency.class_type === "not_set")
      : agencyCoordinates.filter((agency) => agency.class_type === activeClass);

  if (selectedCategory) {
    agencyCoordinates = agencyCoordinates.filter(
      (agency) => agency.class_type === selectedCategory
    );
  }

  return (
    <div className="-mb-32">
      <MapContainer center={centerCoord} zoom={18} scrollWheelZoom={false}>
        <Legend
          counts={counts}
          activeClass={activeClass}
          setActiveClass={setActiveClass}
          raster={raster}
          setRaster={setRaster}
          project_data={project_data}
          project_description={project_description}
          showDescription={showDescription}
          setShowDescription={setShowDescription}
          token={token}
          id={id}
          project_title={project_title}
          classified_image_data={classified_image_data}
        />

        <TileLayer
          attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
          url={`https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=${googleMapsApiKey}`}
        />

        <FullscreenControl position="topleft" />

        {filteredAgencyCoordinates.map((trainPoint, index) => (
          <Marker
            key={index}
            position={[trainPoint.longitude, trainPoint.latitude]}
            icon={trainPoint.icon}
            // icon={customMarkerIcon}
          >
            <Popup>
              <b>{trainPoint.class_type}</b>
              <br />
              <em>{trainPoint.class_value}</em>
              <br />
              Date: {moment(trainPoint.training_date).format("YYYY-MM-DD")}
              <br />
            </Popup>
          </Marker>
        ))}

        <Polygon
          pathOptions={{ color: "#FFFF00", opacity: 0.8, fillOpacity: 0.1 }}
          positions={polygonCoordinates}
        >
          {/* <Tooltip sticky>{data.properties.name}</Tooltip> */}
        </Polygon>

        {/* WMS Layer from GeoServer */}
        {raster !== false && wmsLink !== undefined && (
          <WMSTileLayer
            url={baseUrl}
            layers={layers}
            format={format}
            transparent={true}
            version={version}
            attribution="Zowasel"
          />
        )}
      </MapContainer>
    </div>
  );
};
