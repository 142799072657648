import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useRef, useState } from "react";

const DownloadReportButton = ({ share, reportRef }) => {
  const [open, setOpen] = useState(false);
  //   const reportRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const flattenObject = (obj, parent = "", res = {}) => {
    for (let key in obj) {
      const propName = parent ? `${parent}_${key}` : key;
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], propName, res);
      } else if (Array.isArray(obj[key]) && key === "coordinates") {
        res[`${parent}_geometry`] = obj[key].flat().join(" ");
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  const downloadCSV = () => {
    const flattenedData = flattenObject(share);
    const worksheet = XLSX.utils.json_to_sheet([flattenedData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    const csvData = XLSX.write(workbook, { bookType: "csv", type: "array" });
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "report.csv");
    handleClose();
  };

  const downloadPDF = async () => {
    const reportElement = reportRef.current;

    if (!reportElement) {
      console.error("Invalid element provided to html2canvas");
      return;
    }

    try {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const options = {
        callback: function (pdf) {
          pdf.save("report.pdf");
        },
        margin: [20, 2, 20, 2], 
        html2canvas: {
          allowTaint: true, 
          dpi: 300, 
          letterRendering: true, 
          logging: false, 
          scale: 0.2,
        },
        x: 0, 
        y: 0, 
        width: 210, 
        windowWidth: 1024, 
      };

      pdf.html(reportElement, options);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }

    handleClose();
  };

  return (
    <div className="my-4">
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        className="bg-blue-500 hover:bg-blue-700"
      >
        Download Report
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="p" className="font-medium">
            Select Format
          </Typography>
        </DialogTitle>
        <DialogContent>
          <p>Select the format in which you want to download the report.</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={downloadCSV}
            color="primary"
            className="text-blue-500"
            variant="outlined"
          >
            CSV
          </Button>
          <Button
            onClick={downloadPDF}
            color="primary"
            className="text-blue-500"
            variant="outlined"
          >
            PDF
          </Button>
          <Button
            onClick={handleClose}
            color="error"
            className="text-gray-500"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DownloadReportButton;
