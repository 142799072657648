import React, { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { backend_url } from "../../../services/Constants";
import axios from "axios";
import MRVSnackbar from "../../../components/MRVSnackbar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Loading from "../../../components/Loading";
import AddIcon from "@mui/icons-material/Add";
import { format } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditIcon from "@mui/icons-material/Edit";

export const AddSeason = (props) => {
  const { token, proj_id, edit } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const schema = yup.object().shape({
    year_from: yup.string().notRequired(),
    year_to: yup.string().notRequired(),
  });

  //   const defaultValues = {
  //     year_from: dayjs(season_from[0]) || null,
  //     year_to: dayjs(season_to[0]) || null,
  //   };

  const defaultValues = {
    year_from: null,
    year_to: null,
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function submit({ year_from, year_to }) {
    const farmInfo = {
      project: proj_id,
      year_from: format(new Date(year_from), "yyyy-MM-dd"),
      year_to: format(new Date(year_to), "yyyy-MM-dd"),
    };

    axios
      .put(
        `${backend_url}/farm/season/`,
        farmInfo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Farm Season Updated Successfully.");
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.response.data.details);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      {edit ? (
        <IconButton
          color="primary"
          startIcon={<AddIcon size={20} />}
          onClick={handleClickOpen}
        >
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          className="whitespace-nowrap"
          variant="contained"
          color="secondary"
          startIcon={<AddIcon size={20} />}
          onClick={handleClickOpen}
        >
          Add Season Year
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography
            component="p"
            variant="p"
            className="px-0 py-4 text-sm md:text-md font-semibold sm:font-bold"
          >
            Add Season Year
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form
            name="updateSeason"
            noValidate
            className="flex flex-col justify-center w-full"
            onSubmit={handleSubmit(submit)}
          >
            {openMessage === true && (
              <MRVSnackbar
                openMessage={openMessage}
                severity={severity}
                message={message}
              />
            )}

            <div className="sm:flex m-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="year_from"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{ pr: 2, mb: 3, mt: 2 }}
                      clearable
                      slotProps={{
                        textField: {
                          id: "from-date",
                          label: "Year from e.g(2023)",
                          error: errors.year_from,
                          helperText: errors?.year_from?.message,
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  name="year_to"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{ pr: 2, mb: 3, mt: 2 }}
                      clearable
                      slotProps={{
                        textField: {
                          id: "from-date",
                          label: "Start Date",
                          error: errors.year_to,
                          helperText: errors?.year_to?.message,
                          InputLabelProps: {
                            shrink: true,
                          },
                          fullWidth: true,
                          variant: "outlined",
                        },
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>

            <div className="flex flex-row items-left justify-between">
            <Button
                sx={{ mt: 4 }}
                variant="outlined"
                color="error"
                onClick={() => {
                  setOpenMessage(false);
                  setOpen(false);
                  setLoading(false);
                }}
              >
                Cancel
              </Button>

              <Button
                className="flex justify-end"
                sx={{ mt: 4 }}
                variant="contained"
                color="success"
                onClick={() => {
                  setOpenMessage(false);
                  setLoading(true);
                }}
                type="submit"
              >
                {loading === true ? <Loading /> : "Update Season Year"}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
