import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
// import _ from "../@lodash";
import axios from "axios";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
// import InputAdornment from "@mui/material/InputAdornment";
import {
  Container,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import logohome from "../assets/logohome.png";
import Loading from "../components/Loading";
import MRVSnackbar from "../components/MRVSnackbar";
import { backend_url } from "../services/Constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function SignUp() {
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  const schema = yup.object().shape({
    username: yup.string().required("You must enter user name"),
    shared_id: yup
      .string()
      .required("You must enter your phone number"),
    email: yup
      .string()
      .email("You must enter a valid email")
      .required("You must enter a valid email"),
    password: yup
      .string()
      .required("Please enter your password.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    re_password: yup
      .string()
      .required("Please reenter your password.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    acceptTermsConditions: yup
      .boolean()
      .oneOf([true], "The terms and conditions must be accepted."),
  });

  const defaultValues = {
    username: "",
    email: "",
    password: "",
    re_password: "",
    shared_id: "",
    acceptTermsConditions: false,
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submit({ username, password, re_password, email, shared_id }) {
    const user = {
      username,
      email,
      password,
      re_password,
      telephone_number: shared_id,
      _id: shared_id,
    };
    axios
      .post(
        `${backend_url}/auth/user/register/`,
        user,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Signed Up Successfully!");
        setLoading(false);
        localStorage.clear();
        // window.location.href = "/confirmation";
        window.location.href = "/";
      })
      .catch((err) => {
        // console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data[0]?.details
            ? err.response.data[0]?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  }

  return (
    <Container component="main" maxWidth="xl">
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32 mt-56">
        <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
          <div className="w-full sm:w-auto py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-256 sm:w-256 mx-auto sm:mx-0">
              <img className="w-full sm:w-70" src={logohome} alt="logo" />

              <br />

              {openMessage === true && (
                <MRVSnackbar
                  openMessage={openMessage}
                  severity={severity}
                  message={message}
                />
              )}

              <Typography
                variant="p"
                component="p"
                className="flex flex-col pt-16 sm:mt-32 text-xl sm:text-2xl font-bold sm:font-extrabold tracking-tight leading-tight"
              >
                Sign Up
              </Typography>

              <div className="sm:flex items-baseline pt-6 text-xs sm:text-sm font-small sm:font-medium">
                <Typography variant="p">Already have an account?</Typography>
                <Link className="ml-4 font-semibold underline" to="/">
                  Sign in
                </Link>
              </div>

              <form
                name="registerForm"
                noValidate
                className="flex flex-col justify-center w-full mt-16 sm:mt-32"
                onSubmit={handleSubmit(submit)}
              >
                <FormControl variant="outlined" sx={{ mb: 2 }} fullWidth>
                  <InputLabel id="user-type-label">User Type</InputLabel>
                  <Select
                    labelId="user-type-label"
                    value={userType}
                    onChange={handleUserTypeChange}
                    label="User Type"
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="farmer">Farmer</MenuItem>
                    <MenuItem value="business">Business</MenuItem>
                    <MenuItem value="sampleTaker">Sample Taker</MenuItem>
                  </Select>
                </FormControl>

                {userType && (
                  <>
                    <Controller
                      name="shared_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mb: 2 }}
                          type="number"
                          label="Phone Number"
                          error={!!errors.shared_id}
                          helperText={errors?.shared_id?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />

                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mb: 2 }}
                          label="Email"
                          type="email"
                          error={!!errors.email}
                          helperText={errors?.email?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />

                    <Controller
                      name="username"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mb: 2 }}
                          label="Username"
                          error={!!errors.username}
                          helperText={errors?.username?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />

                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mb: 2 }}
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          error={!!errors.password}
                          helperText={errors?.password?.message}
                          variant="outlined"
                          required
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />

                    <Controller
                      name="re_password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mb: 2 }}
                          label="Password Confirm"
                          type={showPassword1 ? "text" : "password"}
                          error={!!errors.re_password}
                          helperText={errors?.re_password?.message}
                          variant="outlined"
                          required
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword1}
                                  edge="end"
                                >
                                  {showPassword1 ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </>
                )}
                <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between my-8">
                  <Controller
                    name="acceptTermsConditions"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        className="items-center "
                        error={!!errors.acceptTermsConditions}
                      >
                        <FormControlLabel
                          label={
                            <Link
                              className="underline"
                              to="https://www.zowasel.com/Terms-Conditions.html"
                              target="_blank"
                            >
                              {" "}
                              I agree to the Terms of Service and Privacy Policy
                            </Link>
                          }
                          control={<Checkbox size="small" {...field} />}
                        />
                        <FormHelperText>
                          {errors?.acceptTermsConditions?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  className=" w-full mt-16"
                  aria-label="Sign up"
                  // disabled={_.isEmpty(dirtyFields) || !isValid}
                  onClick={() => {
                    setOpenMessage(false);
                    setLoading(Object.keys(errors).length > 0 ? false : true);
                  }}
                  type="submit"
                  size="large"
                >
                  {loading === true ? (
                    Object.keys(errors).length > 0 ? (
                      setLoading(false)
                    ) : (
                      <Loading />
                    )
                  ) : (
                    "Create your account"
                  )}
                </Button>
              </form>
            </div>
          </div>

          <Box
            className="relative hidden md:flex flex-auto items-center justify-center min-h-full p-64 lg:px-112 overflow-hidden"
            sx={{ backgroundColor: "primary.dark" }}
          >
            <svg
              className="absolute inset-0 pointer-events-none"
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Box
                component="g"
                sx={{ color: "primary.light" }}
                className="opacity-20"
                fill="none"
                stroke="currentColor"
                strokeWidth="100"
              >
                <circle r="234" cx="196" cy="23" />
                <circle r="234" cx="790" cy="491" />
              </Box>
            </svg>
            <Box
              component="svg"
              className="absolute -top-64 -right-64 opacity-20"
              sx={{ color: "primary.light" }}
              viewBox="0 0 220 192"
              width="220px"
              height="192px"
              fill="none"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </Box>

            <div className="z-10 relative w-full max-w-2xl">
              <div className="text-7xl font-bold leading-none text-white text-center">
                <div>MRV</div>
                <div>System</div>
              </div>
              {/* <div className="mt-24 text-lg tracking-tight leading-6 text-white">
                                Chief of Staff and Head of Public Service
                            </div> */}
            </div>
          </Box>
        </Paper>
      </div>
    </Container>
  );
}

export default SignUp;
