import React, { useState, lazy, Suspense } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loading from "../../components/Loading";

const FarmSeasonPractices = lazy(() => import("./projectInfo/FarmSeason"));
const CropBaseline = lazy(() => import("./projectInfo/CropBaseline"));
const LandCarbonRights = lazy(() => import("./index/LandCarbonRightsIndex"));
const CropTypes = lazy(() => import("./projectInfo/CropTypes"));
const FarmPractices = lazy(() => import("./index/FarmPracticesIndex"));
const SoilCarbon = lazy(() => import("./projectInfo/SoilCarbon"));
const SoilFertility = lazy(() => import("./projectInfo/SoilFertility"));
const ExistingTree = lazy(() => import("./projectInfo/ExistingTree"));
const FuelEnergy = lazy(() => import("./projectInfo/FuelEnergy"));
// const Irrigation = lazy(() => import("./projectInfo/Irrigation"));

function AddProjectDataSets(props) {
  const { token, proj_id, data_url, setFarmSeason, farmSeason, selectedChip } =
    props;
  const [expanded, setExpanded] = useState([
    "Season",
    "Crop Types",
    data_url ? "Land Rights / Carbon Rights" : "",
  ]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((p) => p !== panel)
    );
  };

  const renderAccordion = (panel, Component) => (
    <Accordion
      expanded={expanded.includes(panel)}
      onChange={handleChange(panel)}
      key={panel}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          component="h5"
          variant="p"
          className="px-4 py-8 text-sm md:text-md font-semibold "
        >
          {panel}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Suspense fallback={<Loading />}>
          <Component
            token={token}
            proj_id={proj_id}
            farmSeason={farmSeason}
            setFarmSeason={setFarmSeason}
            selectedChip={selectedChip}
          />
        </Suspense>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <div
      style={{
        marginTop: "8px",
        marginBottom: "64px",
        marginLeft: "48px",
        marginRight: "48px",
      }}
    >
      <div className=""></div>
      {renderAccordion("Season", FarmSeasonPractices)}
      {renderAccordion("Crop Types", CropTypes)}
      {renderAccordion("Crop Baseline", CropBaseline)}
      {renderAccordion("Land Rights / Carbon Rights", LandCarbonRights)}
      {renderAccordion("Farm Practices & Irrigation", FarmPractices)}
      {/* {renderAccordion("Soil Carbon", SoilCarbon)} */}
      {renderAccordion("Soil Fertility Status  (from mobile)", SoilFertility)}
      {renderAccordion(
        "Existing Tree Cover Per Tree Species (from mobile)",
        ExistingTree
      )}
      {renderAccordion("Fuel Energy Consumption", FuelEnergy)}
      {/* {renderAccordion("Irrigation", Irrigation)} */}
    </div>
  );
}

export default AddProjectDataSets;
