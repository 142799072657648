import React from "react";
import {
  Box,
  Toolbar,
  Menu,
  Container,
  MenuItem,
  Tooltip,
  Button,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import logo1 from "../assets/group_4036.png";
import AppBar from "@mui/material/AppBar";
import { Link, useLocation } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { backend_url } from "../services/Constants";
function Navbar(props) {
  const { user, open, handleDrawerToggle } = props;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const firstSegment = pathSegments[0];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar color="" position="fixed" open={open}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{ flexGrow: 1, ...(open && { xs: "none", display: "none" }) }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box
            sx={{ flexGrow: 1, ...(!open && { xs: "none", display: "none" }) }}
          ></Box>

          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
            <div className="flex flex-1 mx-4">
              <Link to="/" underline="none">
                <img src={logo1} className="font-bold h-24" alt="logo" />
              </Link>
            </div>
          </Box>

          <Button
            size="small"
            variant="text"
            sx={{
              ml: -4,
              mt: 1,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              // fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            MRV
          </Button>

          {firstSegment === "new-project" && (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                // ...(open && { ml: "240px" }),
                // justifyContent: "center",
                // ml: -8,
              }}
            >
              <Link to="/" underline="none">
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    mx: 2,
                    my: 2,
                    fontSize: "24px",
                    fontWeight: 700,
                    display: "flex",
                  }}
                >
                  Create New Project
                </Button>
              </Link>
            </Box>
          )}
          {firstSegment === "new-project" && (
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                }}
              ></Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                }}
              ></Box>
            </>
          )}
          {firstSegment !== "new-project" && (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                ...(open && { ml: "240px" }),
                // justifyContent: "center",
                // ml: -8,
              }}
            >
              <Link to="/" underline="none">
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    mx: 2,
                    my: 2,
                    fontSize: "24px",
                    fontWeight: 700,
                    display: "flex",
                  }}
                >
                  ZOWASEL-MRV
                </Button>
              </Link>
            </Box>
          )}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <Button
                className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
                onClick={handleOpenUserMenu}
                color="inherit"
              >
                <Avatar
                  className="md:mx-4"
                  src={
                    user.profile_photo !== undefined &&
                    `${backend_url}${user.profile_photo}`
                  }
                >
                  {user.username !== undefined && user.username[0]}
                </Avatar>

                <div className="hidden md:flex flex-col mx-4 items-end">
                  <Typography
                    variant="p"
                    component="span"
                    className="font-semibold flex"
                  >
                    {user.first_name
                      ? `${user.first_name} ${user.last_name}`
                      : user.username}
                  </Typography>
                </div>
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Link to="/profile" underline="none">
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon className="min-w-40">
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Profile" />
                </MenuItem>
              </Link>
              <Link to="/sign-out" underline="none">
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon className="min-w-40">
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
                </MenuItem>
              </Link>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
