import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";

function OngoingVerificationWidget({ summary }) {
  return (
    <Paper
      elevation={0}
      className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden mb-8 py-4"
    >
      <div className="text-center justify-between px-8 ">
        <Typography
          variant="p"
          className="px-8 text-md font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          Ongoing Projects
        </Typography>
      </div>
      <div className="text-center mt-8 flex flex-col">
        <Typography
          variant="p"
          className="text-xl sm:text-3xl font-bold tracking-tight leading-none"
        >
          {summary.ongoing_projects_count}
        </Typography>
      </div>
    </Paper>
  );
}

export default memo(OngoingVerificationWidget);
