import { Box, Button, Chip, Typography } from "@mui/material";
import React, { memo } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import UpdateProject from "../measure/UpdateProject";

function ProjectDetailHeader({ data, training, token}) {
  const navigate = useNavigate();
  return (
    training && (
      <Box
        sx={{
          flexShrink: 0,
          backgroundColor: "primary.dark",
          color: "background.paper",
        }}
      >
        <div className="flex w-full container">
          <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 pb-4 md:pb-8">
            <div className="flex flex-row flex-auto">
              <Button className="whitespace-nowrap" variant="text" size="96">
                <ArrowBackIcon size="large" onClick={() => navigate(-1)} />
              </Button>
              <Typography
                variant="p"
                className="pl-3 text-lg font-semibold tracking-tight leading-8"
              >
                {data.properties.name}
              </Typography>

              {/* {training.features.length > 0 && (
              <Typography
                variant="p"
                className="font-medium tracking-tight"
                color="text.secondary"
              >
                {data.properties.description}
              </Typography>
            )} */}
            </div>
            <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
              <Chip
                color="secondary"
                label={
                  data.properties.status === "Notstarted"
                    ? "Not Started"
                    : data.properties.status === "Ongoing"
                    ? "Ongoing"
                    : "Completed"
                }
                variant="outlined"
                clickable
              />
              <UpdateProject token={token} data={data} />
            </div>
          </div>
        </div>
      </Box>
    )
  );
}

export default memo(ProjectDetailHeader);
