import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function VerifySummaryWidget() {
  //   const widgets = useSelector(selectWidgets);
  //   const { status, date, limit, spent, minimum } = widgets?.previousStatement;

  return (
    <Paper className="relative flex flex-col flex-auto p-24 pr-12 pb-12 rounded-2xl shadow overflow-hidden">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <Typography
            variant="p"
            className="text-lg font-medium tracking-tight leading-6 truncate"
          >
            Project Summary
          </Typography>
          <Typography
            variant="p"
            className="text-green-600 font-medium text-sm"
          >
            Start Date: 2018-08-01
          </Typography>
          <Typography variant="p" className="text-red-600 font-medium text-sm">
            End Date: 2019-12-31
          </Typography>
        </div>
        <div className="-mt-8">
          <IconButton aria-label="more" size="large">
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-row flex-wrap mt-16 -mx-24">
        <div className="flex flex-col mx-24 my-12">
          <Typography
            variant="p"
            color="text.secondary"
            className="text-sm font-medium leading-none"
          >
            Total Points
          </Typography>
          <Typography
            variant="p"
            className="mt-8 font-medium text-lg leading-none"
          >
            56
          </Typography>
        </div>
        <div className="flex flex-col mx-24 my-12">
          <Typography
            variant="p"
            color="text.secondary"
            className="text-sm font-medium leading-none"
          >
            Covered Points
          </Typography>
          <Typography
            variant="p"
            className="mt-8 font-medium text-lg leading-none"
          >
            34
          </Typography>
        </div>
        <div className="flex flex-col mx-24 my-12">
          <Typography
            variant="p"
            color="text.secondary"
            className="text-sm font-medium leading-none"
          >
            Assigned to
          </Typography>
          <Typography
            variant="p"
            className="mt-8 font-medium text-lg leading-none"
          >
            Jane Doe
          </Typography>
        </div>
      </div>

      <div className="text-9xl absolute bottom-0 right-0 rtl:left-0 w-96 h-96 -m-8">
        <CheckCircleOutlineIcon
          fontSize="inherit"
          className="opacity-25 text-green-500 dark:text-green-400"
        />
      </div>
    </Paper>
  );
}

export default memo(VerifySummaryWidget);
