import * as React from "react";
import PropTypes from "prop-types";
import { AddProjectMembers } from "../measure/AddProjectMembers";
import { useEffect, useState } from "react";
import { backend_url, zowasel_url } from "../../services/Constants";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import { IconButton, Input } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Loading from "../../components/Loading";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import MRVSnackbar from "../../components/MRVSnackbar";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "project",
    numeric: true,
    disablePadding: false,
    label: "Project",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date Added",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: "#29333F" }}>
      <TableRow className="flex rounded-full">
        <TableCell
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
            fontSize: "15px",
            color: "#fff",
          }}
        >
          S/N
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: "bold", fontSize: "15px", color: "#fff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: "#fff !important" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* {user.is_superuser === true && ( */}
        <TableCell
          align={"right"}
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
            fontSize: "15px",
            color: "#fff",
          }}
        >
          More
        </TableCell>
        {/* )} */}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ProjectMembers(props) {
  const {
    token,
    id,
    project_title,
    searchValue,
    teamPage,
    sampleTakers,
    sampleFarmers,
    user,
  } = props;
  const [members, setMembers] = useState();
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFarmerValue, setSearchFarmerValue] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [offset, setOffset] = useState(0);
  const limit = 50;

  const members_data = members && members.results ? members.results : [];

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      let url;
      if (teamPage) {
        url =
          `${backend_url}/farm/all_members/?` +
          new URLSearchParams({
            offset: offset,
            limit: limit,
          });
      } else {
        const queryParams = new URLSearchParams({
          offset: offset,
          limit: limit,
          project_id: id,
          role: sampleFarmers ? "Farmer" : sampleTakers ? "Sample" : undefined,
        });

        if (searchFarmerValue) {
          queryParams.append("user", searchFarmerValue);
        }

        url = `${backend_url}/farm/members/?${queryParams.toString()}`;
      }

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        let membersWithDetails;

        if (teamPage) {
          membersWithDetails = await Promise.all(
            data.map(async (member) => {
              const detailedResults = await Promise.all(
                member.members.results.map(async (result) => {
                  const memberDetails =
                    result.role === "Farmer" || result.role === "Sample"
                      ? await fetchMemberDetails(result._id)
                      : await fetchBusinessMemberDetails(result._id);
                  return { ...result, ...memberDetails };
                })
              );
              return {
                ...member,
                members: { ...member.members, results: detailedResults },
              };
            })
          );
        } else {
          membersWithDetails = await Promise.all(
            data.results.map(async (member) => {
              const memberDetails = await fetchMemberDetails(member._id);
              return { ...member, ...memberDetails };
            })
          );
        }

        setMembers(membersWithDetails);
        setFilteredMembers(membersWithDetails);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [
    token,
    teamPage,
    searchValue,
    searchFarmerValue,
    id,
    sampleFarmers,
    sampleTakers,
    offset,
    limit,
    
  ]);

  const fetchMemberDetails = async (id) => {
    try {
      const url = `${zowasel_url}/controls/farmers/getbyid/${id}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching member details:", error);
      return {};
    }
  };

  const fetchBusinessMemberDetails = async (id) => {
    try {
      const url = `${zowasel_url}/controls/business/getbyid/${id}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching member details:", error);
      return {};
    }
  };

  useEffect(() => {
    let filtered = [];

    if (teamPage) {
      filtered =
        members !== undefined &&
        members.reduce((acc, member) => {
          const filteredResults = member.members.results.filter((result) => {
            if (result.role === "Business") {
              return result.result.businessname
                .toLowerCase()
                .includes(searchValue.toLowerCase());
            } else if (result.role === "Farmer" || result.role === "Sample") {
              if (result.data && result.data.farmer) {
                const fullname = `${result.data.farmer.firstname} ${result.data.farmer.lastname}`;
                return fullname
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              }
            }
            return false;
          });

          if (filteredResults.length > 0) {
            acc.push({
              ...member,
              members: { ...member.members, results: filteredResults },
            });
          }
          return acc;
        }, []);
    } else {
      filtered =
        members !== undefined &&
        // searchFarmerValue !== undefined &&
        members.filter((member) =>
          member.role === "Farmer" || "Sample"
            ? `${member.data.farmer.accountname}`
                .toLowerCase()
                .includes(searchFarmerValue.toLowerCase())
            : `${member.result.businessname}`
                .toLowerCase()
                .includes(searchFarmerValue.toLowerCase())
        );
    }

    setFilteredMembers(filtered);
  }, [searchValue, searchFarmerValue, members, teamPage]);

  function deleteMember(member_id) {
    axios
      .delete(
        `${backend_url}/farm/members/?` +
          new URLSearchParams({
            project_id: id,
            member_id: member_id,
          }),
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Member Deleted Successfully!");
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        // setMessage(err.response.data);
        setMessage(
          err.response.data?.error
            ? err.response.data?.details
            : err.response.data
        );
        setLoading(false);
      });
  }

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("size");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = members_data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setOffset(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members_data.length) : 0;

  // const visibleRows = useMemo(
  //   () =>
  //     stableSort(members_data, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  return members !== undefined ? (
    <div className={!teamPage ? " mx-24 " : "mx-2"}>
      <Paper
        elevation={0}
        className={
          !teamPage
            ? "flex flex-col flex-auto w-full p-12 shadow rounded-2xl overflow-hidden mt-0"
            : "flex flex-col flex-auto w-full p-12 shadow rounded-2xl overflow-hidden mt-0"
        }
      >
        {openMessage === true && (
          <MRVSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
          />
        )}
        <div className="flex flex-col sm:flex-row items-start justify-between pb-12">
          {sampleFarmers || sampleTakers ? (
            <Paper className="flex items-center  space-x-8 px-16 border-1 shadow-0 mb-12 py-4">
              <Input
                placeholder="Search by name"
                className="flex flex-1"
                disableUnderline
                fullWidth
                value={searchFarmerValue}
                inputProps={{
                  "aria-label": "Search by name",
                }}
                onChange={(e) => setSearchFarmerValue(e.target.value)}
              />
              <IconButton>
                <SearchIcon color="primary" />
              </IconButton>
            </Paper>
          ) : (
            <Typography
              variant="p"
              className="text-md text-center font-semibold  "
            >
              Team Members
            </Typography>
          )}

          {!teamPage && (
            <AddProjectMembers
              sampleTakers={sampleTakers}
              sampleFarmers={sampleFarmers}
              token={token}
              project_id={id}
              project_title={project_title}
            />
          )}
        </div>

        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={members_data.length}
              user={user}
            />
            {loading && <Loading />}
            {!sampleTakers && !sampleFarmers && members.length <= 0 && (
              <Box className="my-16">
                <Typography variant="p" component={"p"}>
                  No team members found.
                </Typography>
              </Box>
            )}
            {sampleTakers && members.length <= 0 && (
              <Box className="my-16">
                <Typography variant="p" component={"p"}>
                  There are no sample takers added to this project.
                </Typography>
              </Box>
            )}
            {sampleFarmers && members.length <= 0 && (
              <Box className="my-16">
                <Typography variant="p" component={"p"}>
                  There are no farmers added to this project.
                </Typography>
              </Box>
            )}
            <TableBody>
              {teamPage
                ? members !== undefined &&
                  filteredMembers.map((project, index) => {
                    return (
                      project !== undefined &&
                      project.members.results !== undefined &&
                      project.members.results.map((member) => {
                        const isItemSelected = isSelected(member.user);
                        return (
                          <TableRow
                            hover
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            // sx={{ cursor: "pointer" }}
                            sx={{
                              py: 2,
                              // "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                              }}
                            >
                              {(index += 1)}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                              }}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              {(member.role === "Farmer" ||
                                member.role === "Sample") &&
                              member.data &&
                              member.data.farmer &&
                              member.data.farmer.firstname
                                ? member.data.farmer.firstname
                                : " "}
                              {member.data &&
                              member.data.farmer &&
                              member.data.farmer.lastname
                                ? ` ${member.data.farmer.lastname}`
                                : " "}

                              {member.role === "Business" &&
                              member.result &&
                              member.result.businessname
                                ? member.result.businessname
                                : " "}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                              }}
                              align="right"
                            >
                              {project.name}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                              }}
                              align="right"
                            >
                              {member.role}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                              }}
                              align="right"
                            >
                              {moment(member.date).format("YYYY-MM-DD")}
                            </TableCell>

                            {/* {user.is_superuser === true && ( */}
                            <TableCell
                              sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                              }}
                              variant="p"
                              align="right"
                            >
                              <IconButton
                                variant="contained"
                                color="error"
                                type="submit"
                                onClick={() => deleteMember(member.id)}
                              >
                                {loading === true ? (
                                  <Loading />
                                ) : (
                                  <DeleteIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            {/* )} */}
                          </TableRow>
                        );
                      })
                    );
                  })
                : filteredMembers.map((member, index) => {
                    const isItemSelected = isSelected(member.user);
                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                        // sx={{ cursor: "pointer" }}
                        sx={{
                          py: 2,
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                          }}
                        >
                          {(index += 1)}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                          }}
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {member.data.farmer.firstname}{" "}
                          {member.data.farmer.lastname}
                        </TableCell>
                        {!teamPage && (
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                            }}
                            align="right"
                          >
                            {project_title}
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                          }}
                          align="right"
                        >
                          {member.role}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                          }}
                          align="right"
                        >
                          {moment(member.date).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                          }}
                          variant="p"
                          align="right"
                          //   className="inline text-12 font-semibold py-4 px-0 rounded-full truncate bg-blue-800 text-white"
                        >
                          <IconButton
                            variant="contained"
                            color="error"
                            type="submit"
                            onClick={() => deleteMember(member.member_id)}
                          >
                            {loading === true ? <Loading /> : <DeleteIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)" }}
                    colSpan={6}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[1]}
          component="div"
          count={50}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  ) : (
    <Loading />
  );
}
