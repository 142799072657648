import { useEffect, useState } from "react";
import { Container, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { backend_url } from "../services/Constants";
import { UpdateProfile } from "./UpdateProfile";
import ReportsWidget from "../home/widgets/ReportsWidget";
import Loading from "../components/Loading";
import { ChangePassword } from "./ChangePassword";

function ProfilePage(props) {
  const { user, token } = props;
  const currentPage = 1;
  const [projects, setProjects] = useState();
  const project_data = projects && projects.results ? projects.results : [];

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/project/?` +
          new URLSearchParams({
            mrv_p: currentPage,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setProjects(data));
    }

    fetchData();
  }, [token, currentPage]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div>
      <Container component="main" maxWidth="xl">
        <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:px-32 pt-72">
          <div className="flex flex-auto justify-center w-full max-w-5xl mx-auto p-12 sm:px-32">
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="w-full"
            >
              <div className="md:flex">
                <div className="flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
                  <Card
                    component={motion.div}
                    variants={item}
                    className="w-full mb-16"
                  >
                    <div className="px-16 pt-12 flex ">
                      {/* <Typography
                        variant="p"
                        className="flex flex-1 text-xl font-semibold leading-tight"
                      >
                        Profile Information
                      </Typography> */}

                      <div className="flex flex-col flex-1 lg:flex-row items-start mx-auto mt-12 mb-12 px-0 ">
                        <div className="rounded-full">
                          <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1, transition: { delay: 0.1 } }}
                          >
                            <Avatar
                              sx={{
                                borderColor: "background.paper",
                                width: "64px",
                                height: "64px",
                                borderWidth: "4px",
                              }}
                              alt="User avatar"
                              src={
                                user.profile_photo !== undefined &&
                                `${backend_url}${user.profile_photo}`
                              }
                            >
                              {user.username !== undefined &&
                                user.username[0].toUpperCase()}
                            </Avatar>
                          </motion.div>
                        </div>

                        <div className="flex flex-col items-center lg:items-start mt-16 lg:mt-0 lg:ml-16">
                          <Typography
                            variant="p"
                            className="text-lg font-bold leading-none"
                          >
                            {user.first_name
                              ? `${user.first_name} ${user.last_name}`
                              : user.username}
                          </Typography>
                          <Typography variant="p" color="text.secondary">
                            {user.location ? user.location : "N/A"}
                          </Typography>
                        </div>

                        <div className="hidden lg:flex h-32 mx-12 border-l-2" />

                        {projects && project_data.features !== undefined ? (
                          <div className="flex items-center mt-24 lg:mt-0 space-x-24">
                            <div className="flex flex-col items-center">
                              <Typography variant="p" className="font-bold">
                                {project_data.features.length}
                              </Typography>
                              <Typography
                                className="text-sm font-medium"
                                color="text.secondary"
                              >
                                Projects
                              </Typography>
                            </div>
                            <div className="flex flex-col items-center">
                              <Typography variant="p" className="font-bold">
                                {project_data.features.length}
                              </Typography>
                              <Typography
                                className="text-sm font-medium"
                                color="text.secondary"
                              >
                                Verification
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          <Loading />
                        )}
                      </div>

                      <div className="-mx-8 items-end text-end">
                        <UpdateProfile token={token} user={user} />
                        <ChangePassword token={token} user={user} />
                      </div>
                    </div>

                    <Divider />

                    <CardContent className="mx-12 my-12">
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-12 w-full min-w-0 py-0">
                        <div className="mb-8 flex flex-col">
                          <Typography
                            variant="p"
                            className="font-semibold mb-4 text-12"
                          >
                            Gender
                          </Typography>
                          <Typography variant="p">Male</Typography>
                        </div>

                        <div className="mb-8 flex flex-col">
                          <Typography
                            variant="p"
                            className="font-semibold mb-4 text-12"
                          >
                            Date Joined
                          </Typography>
                          <Typography variant="p">
                            February 30th, 2024
                          </Typography>
                        </div>

                        <div className="mb-8 flex flex-col">
                          <Typography
                            variant="p"
                            className="font-semibold mb-4 text-12"
                          >
                            Location
                          </Typography>

                          <div className="flex items-center">
                            <Typography variant="p">
                              {user.location ? user.location : "N/A"}
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div className="mb-8 flex flex-col">
                        <Typography
                          variant="p"
                          className="font-semibold mb-4 text-12"
                        >
                          About Me
                        </Typography>
                        <Typography variant="p">{user.about_me}</Typography>
                      </div>
                    </CardContent>
                  </Card>

                  {projects && project_data && (
                    <ReportsWidget
                      project_data={project_data}
                      token={token}
                      user={user}
                    />
                  )}
                </div>

                {/* <div className="flex flex-col md:w-320"></div> */}
              </div>
            </motion.div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ProfilePage;
