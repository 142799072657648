import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { Link } from "react-router-dom";

function TreeCarbonWidget({ summary }) {
  return (
    <Paper
      elevation={0}
      className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden mb-8 py-4"
      component={Link}
      to="/reports"
    >
      <div className="text-center  px-8 ">
        <Typography
          variant="p"
          className="px-8 text-md  font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          Total Tree Carbon
        </Typography>
      </div>
      <div className="text-center mt-8 flex flex-col">
        <Typography
          variant="p"
          className="text-xl sm:text-3xl font-bold tracking-tight leading-none text-green-500"
        >
          {summary.carbon !== undefined
            ? (summary.carbon.trees_carbon * 0.00367).toFixed(2)
            : 0}
        </Typography>
        <Typography
          variant="p"
          className="text-md font-medium py-3 text-green-600"
        >
          MtCO2e
        </Typography>
      </div>
    </Paper>
  );
}

export default memo(TreeCarbonWidget);
