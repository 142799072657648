import { motion } from "framer-motion";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import { Box, IconButton, Paper } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const faqs = [
  {
    question: "What is an MRV system?",
    answer:
      "An MRV system is a set of practices for tracking, reporting, and ensuring the credibility of data and activities, typically in environmental and climate change projects.",
  },
  {
    question: "Why is an MRV important?",
    answer:
      "An MRV guarantees the quality of the data collected, increases trust among participants and stakeholders, and is necessary for compliance with regulatory requirements and conventions.",
  },
  {
    question: "What are the main building blocks of the MRV system?",
    answer:
      "Monitoring: Data and information collection and integration on a regular basis.\nReporting: Recording and sharing of collected data in the project's database.\nVerification: Independent evaluation to ensure the credibility of the data and numbers.",
  },
  {
    question: "How is data collected in the MRV system?",
    answer:
      "Data collection in the MRV system is done through various approaches, including satellite imagery, ground observations, and mobile platform data capture.",
  },
  {
    question: "How do you ensure data accuracy in the MRV system?",
    answer:
      "Data accuracy is ensured by comparing external data with existing data, validating through various methods, and employing proven standards and techniques.",
  },
  {
    question: "Why is verification important?",
    answer:
      "Verification is crucial as it provides independent validation that the data reported is correct, sufficient, and valid for use.",
  },
  {
    question: "Who are the MRV system stakeholders?",
    answer:
      "Typical stakeholders include governments, non-governmental organizations, international organizations, private enterprises, local organizations, local individuals, and independent auditors.",
  },
  {
    question: "What are the key parts of an MRV?",
    answer:
      "Key components of an MRV include a web portal for data display, a mobile application for data collection, and a dashboard for visualizing statistics and results.",
  },
  {
    question: "Who can use the MRV System?",
    answer:
      "Businesses, farmers, and sample takers are among the entities that can use the MRV system for carbon emission estimations.",
  },
  {
    question: "Who is a sample taker?",
    answer:
      "Sample takers are responsible for collecting physical data and samples needed to measure, report, and verify environmental variables, ensuring the quality and accuracy of data collection.",
  },
  {
    question: "How does a farmer enrol on the system?",
    answer:
      "A farmer can easily enrol by creating an account, registering as a farmer on the system, and tracking their progress based on the farming practices they enter.",
  },
  {
    question: "In which manner is MRV applied in the forestry sector?",
    answer:
      "MRV is applied to track the status of forests, monitor deforestation and forest degradation, assess carbon stock changes, and support REDD+ (Reducing Emissions from Deforestation and Forest Degradation) initiatives.",
  },
  {
    question: "In what ways does MRV contribute to climate change?",
    answer:
      "MRV provides accurate information on carbon emission baselines, evaluates the effectiveness of climate change actions, and tracks progress toward achieving climate change objectives.",
  },
  {
    question: "In this MRV system, where are carbon estimations derived from?",
    answer:
      "Carbon estimations are derived from agroforestry and soil. For soil organic carbon (SOC) monitoring, for example, soil samples are collected and analyzed in a laboratory.",
  },
];

function FAQPage(props) {
  const { user } = props;

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    user && (
      <div>
        <div className="w-full pt-4 sm:pt-4 lg:ltr:pr-0 lg:rtl:pl-0 mt-72">
          <motion.div
            className="px-12 py-8"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <Box className="flex items-center  space-x-8 px-16 border-1 shadow-0 mb-12 py-4">
              <Typography variant="h4" component="h2">
                Frequently Asked Questions (FAQs)
              </Typography>
            </Box>

            <div className="pb-12 w-full">
              {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  className="px-8 py-6 rounded-full border-1 shadow-0"
                >
                  <AccordionSummary
                    expandIcon={<ExpandCircleDownIcon color="primary" />}
                    aria-controls={`panel${index + 1}-content`}
                    id={`panel${index + 1}-header`}
                  >
                    <Typography>{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    )
  );
}

export default FAQPage;
