import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { motion } from "framer-motion";
import TotalVerifiedWidget from "./TotalVerifiedWidget";

function CropsWidget({ summary }) {
  const data = summary;

  const filteredData = Object.keys(data)
    .filter(
      (key) =>
        data[key] > 0 &&
        ![
          "total_hectares",
          "project_count",
          "ongoing_projects_count",
          "notstarted_projects_count",
          "verifying_count",
        ].includes(key)
    )
    .map((key) => ({
      name: key.replace(/_count$/, "").replace("_", " "), // Format name
      value: data[key],
    }));

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="grid grid-cols-2 sm:grid-cols-5 md:grid-cols-7 gap-12 w-full min-w-0 px-12 py-8"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={item}>
        <TotalVerifiedWidget summary={summary} />
      </motion.div>

      {filteredData.map((item, index) => (
        <Paper
          key={index}
          elevation={0}
          className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden mb-8 py-4  min-h-96"
        >
          <div className="text-center justify-between px-8 ">
            <Typography
              variant="p"
              className="text-md font-medium tracking-tight leading-6 "
              color="text.secondary"
            >
              {item.name}
            </Typography>
          </div>
          <div className="text-center mt-8 flex flex-col">
            <Typography
              variant="p"
              className="text-lg tracking-tight leading-none"
            >
              {item.value}
            </Typography>
          </div>
        </Paper>
      ))}
    </motion.div>
  );
}

export default memo(CropsWidget);
