import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { memo } from "react";

function TotalVerifiedWidget({ summary }) {
  return (
    <Paper
      elevation={0}
      className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden mb-8 py-4 min-h-96"
    >
      <div className="text-center justify-between px-8 ">
        <Typography
          variant="p"
          className=" text-md font-medium tracking-tight leading-6"
          color="text.secondary"
        >
          Total Verified
        </Typography>
      </div>
      <div className="text-center mt-8 flex flex-col">
        <Typography variant="p" className="text-lg tracking-tight leading-none">
          {summary.verifying_count}
        </Typography>
      </div>
    </Paper>
  );
}

export default memo(TotalVerifiedWidget);
